import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

import { Observable, of } from 'rxjs';
import { catchError, concatMap, delay, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { MultiFactorActions } from 'src/app/features/multi-factor/store/actions';
import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import { PackagesSelectors } from 'src/app/features/packages/store/selectors';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { PinValidationActions } from 'src/app/features/pin-validation/store/actions';
import { RootStoreState } from 'src/app/store';

import { DeviceUser } from '../../models';
import { GetDeviceCodeResponse } from '../../models/response/get-device-code.response.model';
import { DeviceGroupService } from '../../services';
import { DeviceGroupActions } from '../actions';
import { DeviceGroupSelectors } from '../selectors';

@Injectable()
export class DeviceGroupEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly deviceGroupService: DeviceGroupService,
    private readonly store: Store<RootStoreState.State>
  ) {}

  saveToDeviceGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DeviceGroupActions.SaveToDeviceGroup,
        PinValidationActions.PinVerificationPass,
        MultiFactorActions.MultiFactorAttemptSuccess
      ),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
            this.store.pipe(
              select(DeviceGroupSelectors.isUserOnDevice(action.payload.packageUserGuid))
            )
          )
        )
      ),
      switchMap(([action, deviceCode, isUserOnDevice]) => {
        if (isUserOnDevice) {
          return [];
        }
        return this.deviceGroupService
          .saveToDeviceGroup(action.payload.packageUserGuid, deviceCode)
          .pipe(
            switchMap(() => {
              return [
                DeviceGroupActions.SaveToDeviceGroupSuccessful({
                  payload: { packageUserGuid: action.payload.packageUserGuid },
                }),
                DeviceGroupActions.SetIsDeviceUsersLoading({ isLoading: false }),
                DeviceGroupActions.GetCurrentDeviceUsers(),
              ];
            }),
            catchError((err) => {
              return of([
                DeviceGroupActions.SetIsDeviceUsersLoading({ isLoading: false }),
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to save userDeviceGroup.',
                  },
                }),
              ]);
            })
          );
      })
    )
  );

  saveNotaryToDeviceGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceGroupActions.SaveNotaryToDeviceGroup),
      concatLatestFrom((action) => [
        this.store.pipe(select(PackageUsersSelectors.getSigningAgent)),
      ]),
      map(([_, signingAgent]) =>
        DeviceGroupActions.SaveToDeviceGroup({
          payload: { packageUserGuid: signingAgent?.packageUserGuid },
        })
      )
    )
  );

  getDeviceUsersDelayed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceGroupActions.GetCurrentDeviceUsersDelayed),
      delay(10),
      switchMap((_) => {
        return [DeviceGroupActions.GetCurrentDeviceUsers()];
      })
    )
  );

  getDeviceUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceGroupActions.GetCurrentDeviceUsers),
      withLatestFrom(
        this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
        this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
        this.store.pipe(select(DeviceGroupSelectors.getUsersOnDevice)),
        this.store.pipe(select(DeviceGroupSelectors.getDeviceUsersLoading))
      ),
      switchMap(([_, packageGuid, deviceCode, currentDeviceUsers, isDeviceUsersLoading]) => {
        if (isDeviceUsersLoading) {
          return [DeviceGroupActions.GetCurrentDeviceUsersDelayed()];
        }
        const deviceUsersResponse$ = this.deviceGroupService.getDeviceUsers(
          packageGuid,
          deviceCode
        );

        return deviceUsersResponse$.pipe(
          switchMap((data) => {
            const actions = [];

            actions.push(
              DeviceGroupActions.GetCurrentDeviceUsersSuccessful({
                payload: { deviceUsers: data },
              })
            );

            // This check goes second to ensure we always check on an up to date device users list
            if (
              data?.length > 0 &&
              currentDeviceUsers?.length > 0 &&
              !this.areDeviceGroupUsersEqual(data, currentDeviceUsers)
            ) {
              actions.push(DeviceGroupActions.DeviceGroupUpdated());
            }

            return actions;
          }),
          catchError((err) => {
            return of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get Device Users.',
                },
              })
            );
          })
        );
      })
    )
  );

  initializeSessionDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceGroupActions.GetDeviceCode),
      withLatestFrom(
        this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
        this.store.pipe(select(PackagesSelectors.isPreSign))
      ),
      switchMap(([_, deviceCode, isPresign]) => {
        const getDeviceResponse$: Observable<GetDeviceCodeResponse> = !!deviceCode
          ? this.deviceGroupService.getVerifiedDeviceCode(deviceCode)
          : this.deviceGroupService.getNewDeviceCode(isPresign);

        return getDeviceResponse$.pipe(
          switchMap((response) => {
            const actions: TypedAction<any>[] = [
              DeviceGroupActions.SetDeviceCode({
                payload: { deviceCode: response.deviceCode },
              }),
            ];

            if (response.deviceCode !== deviceCode) {
              actions.push(DeviceGroupActions.DeviceCodeChanged());
            }

            return actions;
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get new DeviceCode',
                  exceptionType: ExceptionType.Other,
                },
              })
            )
          )
        );
      })
    )
  );

  isDeviceUserLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DeviceGroupActions.SaveToDeviceGroup,
        PinValidationActions.PinVerificationPass,
        MultiFactorActions.MultiFactorAttemptSuccess
      ),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(
              select(DeviceGroupSelectors.isUserOnDevice(action.payload.packageUserGuid))
            )
          )
        )
      ),
      switchMap(([_, isUserOnDevice]) => {
        if (!isUserOnDevice) {
          return [DeviceGroupActions.SetIsDeviceUsersLoading({ isLoading: true })];
        }
        return [DeviceGroupActions.SetIsDeviceUsersLoading({ isLoading: false })];
      })
    )
  );

  isPINOrMFASuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PinValidationActions.PinVerificationPass,
        MultiFactorActions.MultiFactorAttemptSuccess
      ),
      switchMap((action) => {
        return [DeviceGroupActions.SetIsPINOrMFAComplete({ isPinOrMfaComplete: true })];
      })
    )
  );

  private areDeviceUsersEqual(firstUser: DeviceUser, secondUser: DeviceUser): boolean {
    return (
      firstUser.packageUserGuid === secondUser.packageUserGuid &&
      firstUser.userRoleCode === secondUser.userRoleCode
    );
  }

  private areDeviceGroupUsersEqual(firstGroup: DeviceUser[], secondGroup: DeviceUser[]): boolean {
    return (
      firstGroup?.length === secondGroup?.length &&
      firstGroup.every((deviceUser, index) =>
        this.areDeviceUsersEqual(deviceUser, secondGroup[index])
      )
    );
  }
}
