import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { interval, of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  map,
  mapTo,
  startWith,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';

import { DeviceGroupSelectors } from 'src/app/features/device-group/store/selectors';
import { DocumentsActions } from 'src/app/features/documents';
import { EndorsementsActions, EndorsementsSelectors } from 'src/app/features/endorsements';
import { GenericSpinnerComponent, ModalsActions } from 'src/app/features/modals';
import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { PackagesActions, PackagesSelectors } from 'src/app/features/packages';
import { PresignActions } from 'src/app/features/pre-sign/store';
import { SignalRActions } from 'src/app/features/signal-r';
import { RootStoreState } from 'src/app/store';
import { v4 as uuid } from 'uuid';

import { SigningCompletedComponent } from '../../components';
import { SigningCompletedModalComponent } from '../../components/signing-completed-modal';
import { SigningRoomService } from '../../services';
import { SigningRoomActions } from '../actions';

@Injectable()
export class SigningRoomEffects {
  private readonly failedToGetAttemptCompletion = 'Failed to get attempt completion.';

  connectToSigningRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SigningRoomActions.AttemptSigningRoomConnection),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)))
        )
      ),
      switchMap(([action, packageUserGuid]) => {
        return [
          SignalRActions.AttemptSignalRHubConnection({
            payload: { packageUserGuid },
          }),
        ];
      })
    )
  );

  $signingSessionStarted = createEffect(() =>
    this.actions$.pipe(
      ofType(SigningRoomActions.SigningSessionStarted),
      switchMap(() => [
        DocumentsActions.GetDocuments({
          payload: { ignoreIfLoaded: true, refetchSmartDocs: true },
        }),
        EndorsementsActions.FetchEndorsementImages(),
        ModalsActions.DisableGlobalSpinner(),
      ])
    )
  );

  cancelAttemptPreSignCompletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SigningRoomActions.CancelAttemptPreSignCompletion),
      map(() => ModalsActions.HideLoadingSpinner())
    )
  );

  attemptPreSignCompletionPolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SigningRoomActions.StartAttemptPreSignCompletion),
      exhaustMap(() =>
        interval(5000).pipe(
          startWith(0),
          mapTo(SigningRoomActions.AttemptPreSignCompletion()),
          takeUntil(this.cancelAttemptPreSignCompletion$)
        )
      )
    )
  );

  attemptPreSignCompletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SigningRoomActions.AttemptPreSignCompletion),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
          )
        )
      ),
      exhaustMap(([_, packageUserGuid, deviceCode]) =>
        this.signingRoomService.attemptCompletion(packageUserGuid, deviceCode).pipe(
          switchMap((payload: any) => {
            if (payload.isComplete) {
              return [
                SigningRoomActions.CancelAttemptPreSignCompletion(),
                SigningRoomActions.SigningSessionCompleted(),
                ModalsActions.SetModalComponent({
                  payload: {
                    component: SigningCompletedComponent,
                    modalTitle: 'Pre-Signing Completed',
                    allowManualClose: false,
                  },
                }),
              ];
            }

            return [ModalsActions.HideLoadingSpinner()];
          }),
          catchError((err) =>
            of(
              ModalsActions.ClearModalComponent(),
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: this.failedToGetAttemptCompletion,
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        )
      )
    )
  );

  showSpinnerWhileAttemptingCompletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SigningRoomActions.AttemptPreSignCompletion),
      map(() => ModalsActions.ShowLoadingSpinner())
    )
  );

  sessionJoined$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SigningRoomActions.SigningSessionJoined),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
          )
        )
      ),
      switchMap(([_, packageUserGuid, deviceCode]) =>
        this.signingRoomService.sessionJoined(packageUserGuid, deviceCode).pipe(
          map(() => SigningRoomActions.SigningSessionJoinedSuccessful()),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to set active document',
                },
              })
            )
          )
        )
      )
    )
  );

  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  attemptSigningRoomPackageCompletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SigningRoomActions.AttemptSigningRoomPackageCompletion),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
            this.store.pipe(
              select(EndorsementsSelectors.selectLastAttemptedEndorsementPackageUserGuid)
            ),
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
          )
        )
      ),
      exhaustMap(([_, packageGuid, packageUserGuid, deviceCode]) =>
        this.signingRoomService
          .attemptPackageCompletion(packageGuid, packageUserGuid, deviceCode)
          .pipe(
            switchMap((payload: any) => {
              if (payload.isComplete) {
                return [
                  PackagesActions.SetCompletePackage(),
                  SigningRoomActions.SigningSessionCompleted(),
                ];
              }

              return [];
            }),
            catchError((err) =>
              of(
                SigningRoomActions.SigningSessionCompleted(),
                PackagesActions.SetCompletePackage(),
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: this.failedToGetAttemptCompletion,
                    exceptionType: ExceptionType.CannotProceed,
                  },
                })
              )
            )
          )
      )
    )
  );

  completeSigningRoomSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SigningRoomActions.CompleteSigningRoomSession),
      concatLatestFrom(() => [
        this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
        this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)),
        this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
      ]),
      exhaustMap(([_, packageGuid, packageUserGuid, deviceCode]) =>
        this.signingRoomService
          .triggerSigningCompletion(packageGuid, packageUserGuid, deviceCode)
          .pipe(
            switchMap(() => {
              return [];
            }),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: this.failedToGetAttemptCompletion,
                    exceptionType: ExceptionType.CannotProceed,
                  },
                })
              )
            )
          )
      )
    )
  );

  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  attemptRonSigningRoomPackageCompletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SigningRoomActions.AttemptRonSigningRoomPackageCompletion),
      concatLatestFrom(() => [
        this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
        this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)),
        this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
      ]),
      exhaustMap(([_, packageGuid, packageUserGuid, deviceCode]) =>
        this.signingRoomService
          .attemptPackageCompletion(packageGuid, packageUserGuid, deviceCode)
          .pipe(
            switchMap((payload: any) => {
              if (payload.isComplete === true) {
                return [
                  PackagesActions.SetCompletePackage(),
                  SigningRoomActions.SigningSessionCompleted(),
                ];
              }
              return [];
            }),
            catchError((err) =>
              of(
                SigningRoomActions.SigningSessionCompleted(),
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: this.failedToGetAttemptCompletion,
                    exceptionType: ExceptionType.CannotProceed,
                  },
                })
              )
            )
          )
      )
    )
  );

  showSpinnerDuringSigningRoomPackageCompletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SigningRoomActions.AttemptSigningRoomPackageCompletion,
        SigningRoomActions.InitiateCompleteSigningRoomSession
      ),
      map(() =>
        ModalsActions.SetStandaloneModalComponent({
          payload: { component: GenericSpinnerComponent },
        })
      )
    )
  );

  openSigningCompleteModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SigningRoomActions.OpenSigningCompletionModal),
      switchMap(() =>
        of(
          ModalsActions.SetStandaloneModalComponent({
            payload: {
              component: SigningCompletedModalComponent,
              shouldFade: true,
            },
          })
        )
      )
    )
  );

  getSigningStartedStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SigningRoomActions.GetSigningStartedStatus),
      switchMap((action) => {
        return this.signingRoomService.getSigningStarted().pipe(
          map((signingStartedResponse) => {
            return SigningRoomActions.SetSigningStartedStatus({
              payload: { hasSigningStarted: signingStartedResponse.hasSigningStarted },
            });
          }),
          catchError((err) => {
            return of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get signing started status',
                  exceptionType: ExceptionType.ReloadRetry,
                },
              })
            );
          })
        );
      })
    )
  );

  configureListeners$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignalRActions.SignalRRoomJoined, PresignActions.PreSignConfigureListeners),
        map(() => {
          this.signingRoomService.configureListeners();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly signingRoomService: SigningRoomService,
    private readonly store: Store<RootStoreState.State>
  ) {}
}
