import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  AudioVideoObserver,
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
  VideoSource,
  VideoTileState,
} from 'amazon-chime-sdk-js';
import { combineLatest, interval, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { AudioVideoConnectionMonitorSelectors } from 'src/app/features/audio-video-connection-monitor/store';
import {
  ExceptionType,
  NotificationsActions,
  NotificationType,
} from 'src/app/features/notifications';
import { PackagesSelectors } from 'src/app/features/packages';
import { RecordingStatus } from 'src/app/features/video-conference/chime/enums/recording-status-modals.enum';
import { AttendeeResponse } from 'src/app/features/video-conference/chime/models/AttendeeResponse';
import { MeetingResponse } from 'src/app/features/video-conference/chime/models/MeetingResponse';
import { ChimeActions, ChimeSelectors } from 'src/app/features/video-conference/chime/store';
import { VideoLayoutService } from 'src/app/features/video/services';
import { VideoActions } from 'src/app/features/video/store';
import { RootStoreState } from 'src/app/store';
import { v4 as uuid } from 'uuid';
import { VideoLayout } from '../../../../video/enums';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit, OnDestroy {
  constructor(
    private readonly store: Store<RootStoreState.State>,
    private readonly videoLayoutService: VideoLayoutService
  ) {
    // Print out Chime Logs to the console
    this.logger = new ConsoleLogger('ChimeLogs', LogLevel.WARN);
    this.deviceController = new DefaultDeviceController(this.logger);
  }

  //Video Layout
  activeLayout: VideoLayout;
  availableLayouts = VideoLayout;
  numberOfActiveVideoStreams: number;

  activeCamera: MediaDeviceInfo;
  activeMicrophone: MediaDeviceInfo;

  onDestroyNotifier$ = new Subject();

  // Client Request Token
  clientRequestToken: any;

  // Meeting
  meetingSession: DefaultMeetingSession;

  // Attendee
  attendeeId: any;

  //AudioVideo
  isLocalVideoActive = false;
  remoteVideoTiles: number[] = [];

  // AudioVideo Remote
  currentRemoteVideoSources: VideoSource[];

  // Recording
  isRecordingActive$: Observable<boolean>;
  showRecordingText = false;

  // Misc
  deviceController: DefaultDeviceController;
  logger: ConsoleLogger;
  observer: AudioVideoObserver;

  // HTML Elements
  @ViewChild('localAudioElement') localAudioElementRef: ElementRef<HTMLAudioElement>;
  @ViewChild('localVideoElement') localVideoElementRef: ElementRef<HTMLVideoElement>;

  @ViewChild('remoteAudioElement') remoteAudioElementRef: ElementRef<HTMLAudioElement>;
  @ViewChild('remoteVideoElement') remoteVideoElementRef: ElementRef<HTMLVideoElement>;

  async ngOnInit(): Promise<void> {
    this.store.dispatch(ChimeActions.GetMeeting());
    this.numberOfActiveVideoStreams = 0;

    this.store
      .select(AudioVideoConnectionMonitorSelectors.getSelectedVideoDevice)
      .pipe(
        takeUntil(this.onDestroyNotifier$),
        filter<MediaDeviceInfo>(Boolean),
        tap((videoDevice: MediaDeviceInfo) => {
          this.activeCamera = videoDevice;
          if (this.isLocalVideoActive === true) {
            this.meetingSession.audioVideo.startVideoInput(this.activeCamera.deviceId);
          }
        })
      )
      .subscribe();

    this.isRecordingActive$ = this.store.pipe(
      select(ChimeSelectors.getRecordingStatus),
      filter((recordingStatus) => !!recordingStatus),
      map((recordingStatus) => {
        if (recordingStatus === RecordingStatus.InProgress) {
          return true;
        } else {
          return false;
        }
      })
    );

    combineLatest([
      this.store.pipe(select(ChimeSelectors.getMeetingDetails)),
      this.store.pipe(select(ChimeSelectors.getAttendeeDetails)),
      this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
    ])
      .pipe(
        takeUntil(this.onDestroyNotifier$),
        filter(
          ([meetingDetails, attendee, activePackageGuid]) =>
            !!meetingDetails && !!attendee && !!activePackageGuid
        ),
        tap(async ([meetingDetails, attendee, activePackageGuid]) => {
          this.attendeeId = attendee.attendee.attendeeId;
          this.clientRequestToken = activePackageGuid;

          await this.setupMeetingSession(meetingDetails, attendee);
        })
      )
      .subscribe();

    this.longPollingGetRecordingStatus();
  }

  async setupMeetingSession(meetingResponse: MeetingResponse, attendeeResponse: AttendeeResponse) {
    const meetingConfiguration = new MeetingSessionConfiguration(meetingResponse, attendeeResponse);
    this.meetingSession = new DefaultMeetingSession(
      meetingConfiguration,
      this.logger,
      this.deviceController
    );
    if (this.meetingSession) {
      this.setupVideoObserver();
    }

    this.initializeChime();
    await this.displayLocalVideo();
  }

  private initializeChime() {
    if (!this.clientRequestToken || !this.attendeeId) {
      NotificationsActions.AddNotification({
        payload: {
          exception: new Error('Client Request Token or Attendee ID is missing'),
          notificationType: NotificationType.Error,
          id: uuid(),
          text: 'Failed to initialize chime',
          exceptionType: ExceptionType.CannotProceed,
        },
      });
    }
  }

  private async displayLocalVideo() {
    try {
      await this.meetingSession.audioVideo.chooseAudioOutput(null);

      this.isLocalVideoActive = true;

      await this.meetingSession.audioVideo.startVideoInput(this.activeCamera.deviceId);

      this.meetingSession.audioVideo.startLocalVideoTile();

      this.startRecording();
    } catch (error) {
      NotificationsActions.AddNotification({
        payload: {
          exception: error,
          notificationType: NotificationType.Error,
          id: uuid(),
          text: 'Error starting local video',
          exceptionType: ExceptionType.CannotProceed,
        },
      });
    }
  }

  setupVideoObserver() {
    this.observer = {
      videoTileDidUpdate: async (tileState: VideoTileState) => {
        if (!tileState.localTile && !this.remoteVideoTiles.includes(tileState.tileId)) {
          this.remoteVideoTiles.push(tileState.tileId);
        }

        this.numberOfActiveVideoStreams = this.meetingSession.audioVideo
          .getAllVideoTiles()
          .filter((tile) => tile.state().active).length;

        this.updateVideoLayout(this.numberOfActiveVideoStreams);

        if (this.numberOfActiveVideoStreams >= 1) {
          this.store
            .select(AudioVideoConnectionMonitorSelectors.getSelectedAudioDevice)
            .pipe(
              takeUntil(this.onDestroyNotifier$),
              filter<MediaDeviceInfo>(Boolean),
              tap(async (audioDevice: MediaDeviceInfo) => {
                this.activeMicrophone = audioDevice;
                await this.meetingSession.audioVideo.startAudioInput(
                  this.activeMicrophone.deviceId
                );
              })
            )
            .subscribe();
        }

        if (this.localVideoElementRef && tileState.localTile) {
          const localVideoElement = this.localVideoElementRef.nativeElement;
          const localAudioElement = this.localAudioElementRef.nativeElement;
          this.meetingSession.audioVideo.bindVideoElement(tileState.tileId, localVideoElement);
          await this.meetingSession.audioVideo.bindAudioElement(localAudioElement);
        }

        if (tileState.boundVideoElement === null && !tileState.localTile) {
          const videoElement = document.getElementById(
            `video-${tileState.tileId}`
          ) as HTMLVideoElement;

          const audioElement = document.getElementById( `audio-${tileState.tileId}` ) as HTMLAudioElement;

          if (videoElement === null || audioElement === null) {
            return;
          }

          this.meetingSession.audioVideo.bindVideoElement(tileState.tileId, videoElement);
          await this.meetingSession.audioVideo.bindAudioElement(audioElement);
        }
      },
      videoTileWasRemoved: (tileId: number) => {
        document.getElementById(`video-${tileId}`).remove();
        document.getElementById(`audio-${tileId}`).remove();
        this.remoteVideoTiles = this.remoteVideoTiles.filter((tid) => tid !== tileId);
      },
      audioVideoDidStart: () => {
        this.store.dispatch(
          VideoActions.SendVideoStreamConnected({ payload: { videoStreamId: this.attendeeId } })
        );
      },
      remoteVideoSourcesDidChange: (videoSources: VideoSource[]) => {
        if (this.currentRemoteVideoSources !== undefined) {
          this.currentRemoteVideoSources.forEach((currentVideoSource: VideoSource) => {
            if (
              !videoSources.some(
                (videoSource) =>
                  videoSource.attendee.attendeeId === currentVideoSource.attendee.attendeeId
              )
            ) {
              const disconnectedAttendeeId = currentVideoSource.attendee.attendeeId;
              this.store.dispatch(
                VideoActions.SendVideoStreamDestroyed({
                  payload: { videoStreamId: disconnectedAttendeeId },
                })
              );
            }
          });
        }

        this.currentRemoteVideoSources = videoSources;
      },
    };

    this.meetingSession.audioVideo.addObserver(this.observer);
    this.meetingSession.audioVideo.start();
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next(undefined);
    this.onDestroyNotifier$.complete();
  }

  updateVideoLayout(newVideoCount: number) {
    this.activeLayout = this.videoLayoutService.calculateLayout(newVideoCount + 1);
  }

  startRecording() {
    this.store.dispatch(VideoActions.ShowStartingRecordingModal());

    setTimeout(() => {
      this.store.dispatch(ChimeActions.StartRecording());
    }, 2000);
  }

  showRecText() {
    this.showRecordingText = true;
  }

  hideRecText() {
    this.showRecordingText = false;
  }

  longPollingGetRecordingStatus() {
    interval(5000)
      .pipe(
        takeUntil(this.onDestroyNotifier$),
        tap(() => {
          this.store.dispatch(ChimeActions.GetRecordingStatus());
        })
      )
      .subscribe();
  }
}
