import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SignalRService } from 'src/app/features/signal-r/services/signal-r.service';
import { delayedRetry } from 'src/app/operators';
import { RootStoreState } from 'src/app/store';

import {
  PostArchiveRequest,
  PostArchiveResponse,
  PostVideoSessionResponse,
  PutArchiveRequest,
  PutArchiveResponse,
  VideoArchiveIdsResponse,
  VideoLink,
  VideoSessionModel,
} from '../models';
import { VideoActions } from '../store/actions';

@Injectable({ providedIn: 'root' })
export class VideoService {
  private listenerConfigured = false;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly signalRService: SignalRService,
    private readonly store: Store<RootStoreState.State>
  ) {}

  postVideoSession(packageUserGuid: string): Observable<PostVideoSessionResponse> {
    return this.httpClient
      .post<PostVideoSessionResponse>('videoConference/videoSession', packageUserGuid)
      .pipe(
        delayedRetry(1000),
        catchError((res) => throwError(res))
      );
  }

  getVideoSessionInfo(): Observable<VideoSessionModel> {
    return this.httpClient.get<VideoSessionModel>('videoConference/videoSession').pipe(
      delayedRetry(1000),
      catchError((res) => throwError(res))
    );
  }

  postStartArchive(request: PostArchiveRequest): Observable<PostArchiveResponse> {
    return this.httpClient.post<PostArchiveResponse>(`videoConference/archive`, request).pipe(
      delayedRetry(1000, 3),
      catchError((res) => throwError(res))
    );
  }

  putStopArchive(request: PutArchiveRequest): Observable<PutArchiveResponse> {
    return this.httpClient.put<PutArchiveResponse>('videoConference/archive', request).pipe(
      delayedRetry(1000),
      catchError((res) => throwError(res))
    );
  }

  postStreamConnected(videoStreamId: string, deviceCode: string) {
    return this.httpClient.post(`videoConference/stream/connected`, {
      videoStreamId,
      deviceCode,
    });
  }

  putStreamDisconnected(videoStreamId: string) {
    return this.httpClient.put(`videoConference/stream/disconnected`, {
      videoStreamId,
    });
  }

  putStreamEnabled(videoStreamId: string) {
    return this.httpClient.put(`videoConference/stream/enabled`, {
      videoStreamId,
    });
  }

  postVideoConnectionIssue(deviceCode: string) {
    return this.httpClient.post(`videoConference/publishers/issues`, {
      deviceCode,
    });
  }

  postVideoVisibilityIssue(deviceCode: string) {
    return this.httpClient.post(`videoConference/subscribers/issues`, {
      deviceCode,
    });
  }

  getAreAllStreamsConnected(packageUserGuid: string) {
    return this.httpClient.get(`videoConference/allVideoStreams/packageUsers/${packageUserGuid}`);
  }

  getVideoArchiveIds(packageGuid: string): Observable<VideoArchiveIdsResponse> {
    return this.httpClient.get<VideoArchiveIdsResponse>(
      `videos/packages/${packageGuid}/videoarchiveids`
    );
  }

  getVideoLink(packageGuid: string, videoArchiveId: number) {
    return this.httpClient.get<VideoLink>(
      `videos/packages/${packageGuid}/videolink/${videoArchiveId}`
    );
  }

  auditVideoDownloaded(packageGuid: string, VideoArchiveId: number, VideoUri: string) {
    return this.httpClient.post(`videos/packages/${packageGuid}/downloaded`, {
      VideoArchiveId: VideoArchiveId,
      VideoUri: VideoUri,
    });
  }

  getArchiveStatus(videoSessionKey: string) {
    return this.httpClient.get<boolean>(`videoConference/archives/${videoSessionKey}/uploadstatus`);
  }

  // clean up listeners only state is important now for modals
  public configureListeners() {
    if (!this.listenerConfigured) {
      this.listenerConfigured = true;

      this.signalRService.hubConnection.on('ReceiveStartArchiveInProgress', () => {
        this.store.dispatch(VideoActions.ShowStartingRecordingModal());
      });

      this.signalRService.hubConnection.on('ReceiveStartArchiveSuccessful', () => {
        this.store.dispatch(VideoActions.HideStartingRecordingModal());
      });

      this.signalRService.hubConnection.on('ReceiveStartArchiveFailed', () => {
        this.store.dispatch(VideoActions.HandleStartArchiveFailed());
      });

      this.signalRService.hubConnection.on('ReceiveVideoStreamDisconnected', () => {
        this.store.dispatch(
          VideoActions.SetAreAllVideoStreamsConnected({
            payload: false,
          })
        );
        this.store.dispatch(VideoActions.ReceivedVideoStreamDisconnected());
      });

      this.signalRService.hubConnection.on('ReceiveVideoStreamConnected', (videoStream) => {
        this.store.dispatch(
          VideoActions.SetAreAllVideoStreamsConnected({
            payload: videoStream.allVideoStreamsConnected,
          })
        );
        this.store.dispatch(VideoActions.ReceivedVideoStreamConnected());
      });

      this.signalRService.hubConnection.on('ReceiveVideoStreamEnabled', (videoStream) => {
        this.store.dispatch(
          VideoActions.SetAreAllVideoStreamsConnected({
            payload: videoStream.allVideoStreamsConnected,
          })
        );
        this.store.dispatch(VideoActions.ReceivedVideoStreamEnabled());
      });
    }
  }
}
