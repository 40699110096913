import { createAction, props } from '@ngrx/store';

export const AttemptSigningRoomConnection = createAction(
  '[Signing Room] Attempt Signing Room Connection'
);

export const StartAttemptPreSignCompletion = createAction(
  '[Signing Room] Start Attempt Pre Sign Completion'
);

export const CancelAttemptPreSignCompletion = createAction(
  '[Signing Room] Cancel Attempt Pre Sign Completion'
);

export const AttemptPreSignCompletion = createAction('[Signing Room] Attempt Pre Sign Completion');

export const SigningSessionCompleted = createAction('[Signing Room] Signing Session Complete');

export const SigningSessionJoined = createAction('[Signing Room] Signing Session Joined');

export const SigningSessionJoinedSuccessful = createAction(
  '[Signing Room] Signing Session Joined Successful'
);

export const SigningSessionStarted = createAction('[Signing Room] Signing Session Started');

// TODO: User Story 50434: Remove Old Endorsement Application Logic
export const AttemptSigningRoomPackageCompletion = createAction(
  '[Signing Room] Attempt Signing Room Package Completion'
);

export const InitiateCompleteSigningRoomSession = createAction(
  '[Signing Room] Initates Complete Ron Signing Room Session'
);

export const CompleteSigningRoomSession = createAction(
  '[Signing Room] Completes Signing Room Package Session'
);

// TODO: User Story 50434: Remove Old Endorsement Application Logic
export const AttemptRonSigningRoomPackageCompletion = createAction(
  '[Signing Room] Attempt Ron Signing Room Package Completion'
);

export const CompleteRonSigningRoomSession = createAction(
  '[Signing Room] Completes Ron Signing Room Session'
);

export const OpenSigningCompletionModal = createAction(
  '[Signing Room] Open Signing Complete Modal'
);

export const ReviewDocuments = createAction('[Signing Room] Review Documents');

export const ExitSigningRoom = createAction('[Signing Room] Exit Signing Room');

export const SetShowCongratulationsPage = createAction(
  '[Signing Room] Update Show Congratulations Page',
  props<{ payload: { showCongratulations: boolean } }>()
);

export const GetSigningStartedStatus = createAction('[Signing Room] get Signing Started Status');

export const SetSigningStartedStatus = createAction(
  '[Signing Room] Set Signing Started Status',
  props<{ payload: { hasSigningStarted: boolean } }>()
);
