import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { combineLatest, interval, Observable, Subject } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';

import { DeviceGroupSelectors } from 'src/app/features/device-group';
import { MsalAuthSelectors } from 'src/app/features/msal-auth';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { RootStoreState } from 'src/app/store';

import { PackagesSelectors } from '../../store';

@Component({
  selector: 'app-signing-details-card',
  templateUrl: './signing-details-card.component.html',
  styleUrls: ['./signing-details-card.component.scss'],
})
export class SigningDetailsCardComponent implements OnInit {
  public propertyAddress$: Observable<string>;
  public scheduledTime$: Observable<string>;
  public startsIn$: Observable<string>;
  public showAddress$: Observable<boolean>;

  private readonly scheduledTimePassedNotifier$ = new Subject();

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.propertyAddress$ = this.store.pipe(
      select(PackagesSelectors.getPackage),
      map((p) => {
        const streetAddress = `${p.streetAddress1} ${
          p.streetAddress2 ? p.streetAddress2 : ''
        }`.trim();
        return `${streetAddress} ${p.city} ${p.stateCode} ${p.zip}`;
      })
    );

    this.showAddress$ = combineLatest([
      this.store.pipe(select(PackageUsersSelectors.getIsActivePackageUserSigningAgent)),
      this.store.pipe(select(DeviceGroupSelectors.getIsPinOrMfaComplete)),
      this.store.pipe(select(MsalAuthSelectors.getIsUserVerified)), //KRON non-SA
    ]).pipe(
      map(
        ([isSigningAgent, isPinOrMfaComplete, msalIsVerified]) =>
          isSigningAgent || isPinOrMfaComplete || msalIsVerified
      )
    );

    const scheduledDate = this.store.pipe(
      select(PackagesSelectors.getPackage),
      filter((p) => !!p.scheduledDateTime),
      map((p) => new Date(p.scheduledDateTime))
    );

    this.scheduledTime$ = scheduledDate.pipe(
      map((date) =>
        date.toLocaleTimeString('en-us', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          timeZoneName: 'short',
        })
      )
    );

    const startsInInterval$ = interval(60 * 1000).pipe(
      startWith(0),
      takeUntil(this.scheduledTimePassedNotifier$)
    );

    this.startsIn$ = combineLatest([scheduledDate, startsInInterval$]).pipe(
      map(([date, _]) => this.calculateStartsIn(date))
    );
  }

  calculateStartsIn(scheduledDate: Date): string {
    const dateDiffInMs = scheduledDate.getTime() - Date.now();
    if (dateDiffInMs <= 0) {
      this.scheduledTimePassedNotifier$.next(undefined);
      this.scheduledTimePassedNotifier$.complete();
      return 'Now';
    }

    const seconds = Math.abs(Math.floor(dateDiffInMs / 1000));
    const minutes = Math.floor(seconds / 60);
    const hourDiff = Math.floor(minutes / 60);
    const minuteDiff = minutes % 60;

    const hourSuffix = hourDiff === 1 ? 'hour' : 'hours';
    const minuteSuffix = minuteDiff === 1 ? 'minute' : 'minutes';

    let dateDiffString: string;
    if (hourDiff > 0 && minuteDiff > 0) {
      dateDiffString = `${hourDiff} ${hourSuffix}, ${minuteDiff} ${minuteSuffix}`;
    } else if (hourDiff > 0 && minuteDiff === 0) {
      dateDiffString = `${hourDiff} ${hourSuffix}`;
    } else {
      dateDiffString = `${minuteDiff} ${minuteSuffix}`;
    }
    return dateDiffString;
  }
}
