import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ModalsActions } from 'src/app/features/modals';
import {
  ExceptionType,
  NotificationsActions,
  NotificationType,
} from 'src/app/features/notifications';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { RecordingStatus } from 'src/app/features/video-conference/chime/enums/recording-status-modals.enum';
import { ChimeService } from 'src/app/features/video-conference/chime/services/chime.service';
import { ChimeActions } from 'src/app/features/video-conference/chime/store/actions';
import { ChimeSelectors } from 'src/app/features/video-conference/chime/store/selectors';
import { RecordingCouldNotStartModalComponent } from 'src/app/features/video/components';
import { VideoActions } from 'src/app/features/video/store';
import { RootStoreState } from 'src/app/store';
import { v4 as uuid } from 'uuid';

@Injectable()
export class ChimeEffects {
  setupChime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChimeActions.SetupChime),
      switchMap(() => {
        return this.chimeService.createMeeting().pipe(
          map((response) => {
            return ChimeActions.GetMeeting();
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to set up chime',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        );
      })
    )
  );

  getMeeting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChimeActions.GetMeeting),
      switchMap(() => {
        return this.chimeService.getMeeting().pipe(
          switchMap((response) => {
            const resultActions: Array<any> = [];

            resultActions.push(ChimeActions.SetMeetingDetails({ payload: { meeting: response } }));
            resultActions.push(
              ChimeActions.AddAttendee({ payload: { meetingId: response.meeting.meetingId } })
            );

            return resultActions;
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get meeting',
                  exceptionType: ExceptionType.None,
                },
              })
            )
          )
        );
      })
    )
  );

  addAttendee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChimeActions.AddAttendee),
      switchMap((action) => {
        return this.chimeService.createAttendee(action.payload.meetingId).pipe(
          map((response) => {
            return ChimeActions.SetAttendeeDetails({ payload: { attendee: response } });
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get meeting',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        );
      })
    )
  );

  startRecording$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChimeActions.StartRecording),
      concatMap((action) =>
        of(action).pipe(withLatestFrom(this.store.select(PackageUsersSelectors.getIsSigningAgent)))
      ),
      switchMap(([action, isSigningAgent]) => {
        if (isSigningAgent) {
          return this.chimeService.startRecording().pipe(
            map(() => {
              return ModalsActions.ClearModalComponent();
            }),
            catchError((err) => {
              return [
                VideoActions.HideStartingRecordingModal(),
                ChimeActions.ShowRecordingFailedToStartModal(),
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to start recording',
                    exceptionType: ExceptionType.None,
                  },
                }),
              ];
            })
          );
        } else {
          return of(ModalsActions.ClearModalComponent());
        }
      })
    )
  );

  stopRecording$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChimeActions.StopRecording),
      switchMap(() => {
        return this.chimeService.stopRecording().pipe(
          switchMap(() => {
            return [];
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to stop recording',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        );
      })
    )
  );

  getRecordingStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChimeActions.GetRecordingStatus),
      switchMap(() => {
        return this.chimeService.getRecordingStatus().pipe(
          switchMap((response) => {
            const recordingStatus =
              RecordingStatus[response.chimeMediaStatus as keyof typeof RecordingStatus] ??
              RecordingStatus.Unknown;

            return [
              ChimeActions.SetRecordingStatus({ payload: { recordingStatus: recordingStatus } }),
            ];
          }),
          catchError((err) => {
            return of(
              ChimeActions.SetRecordingStatus({
                payload: { recordingStatus: RecordingStatus.Unknown },
              }),
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get recording status',
                  exceptionType: ExceptionType.None,
                },
              })
            );
          })
        );
      })
    )
  );

  showRecordingFailedToStartModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChimeActions.ShowRecordingFailedToStartModal),
      concatMap((action) =>
        of(action).pipe(withLatestFrom(this.store.select(ChimeSelectors.getRecordingStatus)))
      ),
      filter(([_, recordingStatus]) => recordingStatus !== RecordingStatus.InProgress),
      map((action) =>
        ModalsActions.SetStandaloneModalComponent({
          payload: {
            component: RecordingCouldNotStartModalComponent,
            shouldFade: true,
          },
        })
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly chimeService: ChimeService,
    private readonly store: Store<RootStoreState.State>
  ) {}
}
