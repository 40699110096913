import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';

import { AudioVideoCheckService } from 'src/app/features/av-check/services';
import { SharedModule } from 'src/app/features/shared';
import { AvCheckGuideComponent } from '../../shared/av-check-guide/av-check-guide.component';

@Component({
  selector: 'app-video-selection',
  standalone: true,
  imports: [
    AvCheckGuideComponent,
    CommonModule,
    FormsModule,
    MatExpansionModule,
    MatIconModule,
    MatSelectModule,
    SharedModule,
  ],
  templateUrl: './video-selection.component.html',
  styleUrl: './video-selection.component.scss',
})
export class VideoSelectionComponent {
  audioVideoCheckService = inject(AudioVideoCheckService);
  showVideoGuideDetails = false;
}
