<mat-expansion-panel
  class="mat-expansion-panel"
  data-testid="av-check-audio-select"
  [expanded]="true"
  hideToggle
>
  <mat-expansion-panel-header>
    <mat-panel-title data-testid="av-check-audio-title"
      ><mat-icon class="material-icons-outlined">mic</mat-icon>Audio</mat-panel-title
    >
    <mat-panel-description>
      <ng-container *ngTemplateOutlet="audioIconContainer" />
    </mat-panel-description>
  </mat-expansion-panel-header>

  <div class="mat-expansion-panel__body">
    <div class="custom-select">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label data-testid="av-check-microphone-select-label">Select Microphone</mat-label>
        <mat-select
          [disabled]="!micCheckService.selectAudioInputDevices().length"
          [value]="micCheckService.currentMicDevice()?.label || ''"
        >
          <mat-option value="" (click)="micCheckService.clearMicrophone()">
            No Microphone Selected
          </mat-option>
          @for (inputDevice of micCheckService.selectAudioInputDevices(); track inputDevice) {
          <mat-option
            [value]="inputDevice.label"
            (click)="micCheckService.selectMicrophone(inputDevice)"
          >
            {{ inputDevice.label }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-progress-bar
        [ngClass]="micCheckService.micBarProgressClass()"
        [value]="micCheckService.micBarProgress()"
        class="rkt-ProgressBar disable-animations"
        mode="determinate"
      />
    </div>
    <ng-container *ngTemplateOutlet="micIconContainer" />
  </div>

  <span class="hint">Hint: Speak into your mic and make sure the bar above turns green</span>
  @if (audioVideoCheckService.hasUserMediaFailure()) {
  <app-av-check-guide
    [showButton]="audioVideoCheckService.isBrowserDetected"
    [(showDetails)]="showMicrophoneGuideDetails"
  >
    @if (audioVideoCheckService.isChrome) {
    <div>
      <p>
        Hi there! It looks like your microphone might be blocked by your chrome browser. Let's get
        this sorted out.
      </p>

      <ul *ngIf="showMicrophoneGuideDetails">
        <li>Click on the three-dot menu icon in the top right corner of the browser.</li>
        <li>From the drop-down menu, select "Settings".</li>
        <li>Scroll down and click on "Privacy and security".</li>
        <li>Click on "Site Settings".</li>
        <li>Under the "Permissions" section, click on the Microphone</li>
        <li>
          Here, you will see a list of sites with blocked and allowed access to your microphone. To
          unblock a site:
          <ul>
            <li>Find the site under the "Blocked" section.</li>
            <li>
              Click on the trash bin icon to remove it from the blocked list, or click on the site
              and change the microphone permission to "Allow".
            </li>
          </ul>
        </li>
        <li>
          If you're using a Chrome device at work or school, your network administrator might have
          set camera and microphone settings for you, in which case you might not be able to change
          them. If you're still having problems, make sure your microphone is the default recording
          device in your system settings and that recording levels are correct.
        </li>
        <li>
          Remember to only grant microphone access to websites you trust for your privacy and
          security. If you continue to experience issues, you may need to check for broader
          permissions in your computer's system settings under Security & Privacy > Privacy >
          Microphone, and ensure Chrome is allowed to use the microphone.'
        </li>
      </ul>
    </div>
    } @else if (audioVideoCheckService.isEdge) {
    <div>
      <p>
        Hi there! It looks like your microphone might be blocked by your edge browser. Let's get
        this sorted out.
      </p>

      <ul *ngIf="showMicrophoneGuideDetails">
        <li>Navigate to the website where you want to unblock the microphone.</li>
        <li>Click on the lock icon next to the website link in the address bar.</li>
        <li>Click on Site permissions.</li>
        <li>Use the drop-down menus to change the microphone permission to Allow.</li>
        <li>
          If needed, click on Reset permissions and then Refresh the page to apply the changes.
        </li>
        <li>
          If you're still having trouble, make sure that the microphone access is turned on in your
          Windows settings:
          <ul>
            <li>Select Start > Settings > Privacy & security > Microphone</li>
            <li>
              In Allow access to the microphone on this device, select Change and make sure
              Microphone access for this device is turned on.
            </li>
            <li>Under Allow apps to access your microphone, make sure it's turned On.</li>
            <li>
              For desktop apps, ensure that Allow desktop apps to access your microphone is turned
              on.
            </li>
          </ul>
        </li>
        <li>
          Remember to only grant microphone access to websites and apps you trust, as this is
          important for your privacy and security. If you continue to experience issues, you may
          need to check for broader permissions in your Windows System Preferences under Security &
          Privacy > Privacy > Microphone, and ensure Edge is allowed to use the microphone.
        </li>
      </ul>
    </div>
    } @else if (audioVideoCheckService.isSafari) {
    <div>
      <p>
        Hi there! It looks like your microphone might be blocked by your safari browser. Let's get
        this sorted out.
      </p>

      <ul *ngIf="showMicrophoneGuideDetails">
        <li>Select Preferences from the dropdown menu.</li>
        <li>Go to the Websites tab.</li>
        <li>Select Microphone from the left sidebar.</li>
        <li>
          You will see a list of websites with their respective microphone access settings. To
          unblock a site, click on the dropdown menu next to it and select Allow.
        </li>
        <li>
          If you're using Safari on an iOS device:
          <ul>
            <li>Open the Settings app.</li>
            <li>Scroll down and tap on Safari.</li>
            <li>Tap on Microphone under the settings for Safari.</li>
            <li>Choose Allow to enable microphone access.</li>
          </ul>
        </li>
        <li>
          Make sure to only grant microphone access to websites you trust, as this is important for
          your privacy and security. If you continue to experience issues, check for broader
          permissions in your Mac's System Preferences under Security & Privacy > Privacy >
          Microphone, and ensure Safari is allowed to use the microphone.
        </li>
      </ul>
    </div>
    } @else {
    <div>
      <p>
        Hi there! It looks like your microphone might be blocked by your browser. Let's get this
        sorted out. Try going to your browsers privacy settings to allow your microphone.
      </p>
    </div>
    }
  </app-av-check-guide>
  } @if (speakerCheckService.hasSpeakerFeature()) {
  <div class="mat-expansion-panel__body">
    <div class="custom-select">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label data-testid="av-check-speaker-select-label">Select Speaker</mat-label>
        <mat-select
          [disabled]="!speakerCheckService.selectAudioOutputDevices().length"
          [value]="speakerCheckService.selectAudioOutputDevice()?.label || ''"
        >
          <mat-option value="" (click)="speakerCheckService.clearSpeaker()">
            No Speaker Selected
          </mat-option>
          @for (outputDevice of speakerCheckService.selectAudioOutputDevices(); track outputDevice)
          {
          <mat-option
            [value]="outputDevice.label"
            (click)="speakerCheckService.selectSpeaker(outputDevice)"
          >
            {{ outputDevice.label }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-progress-bar
        [ngClass]="speakerCheckService.matProgressBarClass"
        [value]="speakerCheckService.audioLevel"
        class="rkt-ProgressBar disable-animations"
        mode="determinate"
      />
    </div>
    <ng-container *ngTemplateOutlet="speakerIconContainer" />
  </div>
  <span class="hint"
    >Hint: Click on the “Test Speaker” button below and ensure that you can hear the sound.</span
  >
  @if (shouldShowSpeakerGuide()) {
  <app-av-check-guide
    [showButton]="audioVideoCheckService.isBrowserDetected"
    [(showDetails)]="showSpeakerGuideDetails"
  >
    @if (audioVideoCheckService.isChrome) {
    <div>
      <p>
        Hi there! It looks like your speaker might be blocked by your chrome browser. Let's get this
        sorted out.
      </p>

      <ul *ngIf="showSpeakerGuideDetails">
        <li>
          Click on the Customize and control Google Chrome button (three dots) and select Settings.
        </li>
        <li>Scroll down and click on Advanced.</li>
        <li>Under the Privacy and security section, select Site settings.</li>
        <li>Click on Sound.</li>
        <li>
          Ensure the toggle is set to Allow sites to play sound (recommended). If it's turned off,
          switch it to On.
        </li>
        <li>
          If you have previously muted a specific site, you can unmute it by:
          <ul>
            <li>
              Right-clicking on the tab of the site and selecting Unmute site from the context menu.
            </li>
            <li>
              Or, clicking on the lock icon next to the URL in the address bar and changing the
              Sound option to Allow.
            </li>
          </ul>
        </li>
        <li>
          Make sure that the volume mixer for Chrome is not muted:
          <ul>
            <li>Right-click on the speaker icon in your taskbar.</li>
            <li>Select Open Volume mixer.</li>
            <li>Ensure that Google Chrome is not muted and the volume slider is up.</li>
          </ul>
        </li>
        <li>
          If you're still facing issues, you might want to check if there are any extensions or
          plugins interfering with the sound. You can try disabling them one by one to identify the
          culprit.
        </li>
        <li>
          Remember, these steps are for the desktop version of Chrome. If you're using Chrome on a
          different platform, the steps may vary slightly.
        </li>
      </ul>
    </div>
    } @else if (audioVideoCheckService.isEdge) {
    <div>
      <p>
        Hi there! It looks like your speaker might be blocked by your edge browser. Let's get this
        sorted out.'
      </p>

      <ul *ngIf="showSpeakerGuideDetails">
        <li>Click on the three dots in the upper right corner and then select Settings.</li>
        <li>Click on Site permissions.</li>
        <li>Click on the Microphone or Sound option.</li>
        <li>
          Use the drop-down menu to select the default device and grant it permissions. Make sure
          the Ask Before Accessing toggle switch is turned on.
        </li>
        <li>
          If a specific tab is muted, look for a speaker icon next to the tab's name and click it to
          unmute. Alternatively, you can right-click the tab and select Unmute Tab, or select the
          tab and press Ctrl + M.
        </li>
        <li>
          Additionally, check the volume mixer for Edge:
          <ul>
            <li>Right-click on the speaker icon near the clock and select Playback Devices.</li>
            <li>Right-click in the box area and check Show disabled and disconnected devices.</li>
            <li>
              Highlight Edge and on the lower right click Properties. Check settings for Device
              Usage enabled and so on.
            </li>
          </ul>
        </li>
        <li>
          If you're still facing issues, consider checking if any extensions are interfering with
          the sound and disable them temporarily to test. Also, ensure that your computer's sound
          settings are correctly configured and that the speakers or headphones are functioning
          properly.
        </li>
      </ul>
    </div>
    } @else {
    <div>
      <p>
        Hi there! It looks like your speaker might be blocked by your browser. Let's get this sorted
        out. Try going to your browsers privacy settings to allow your speaker.
      </p>
    </div>
    }
  </app-av-check-guide>
  }

  <ng-container mat-action-row>
    <app-button
      (click)="!shouldDisableSpeakerTestButton() && speakerCheckService.testSpeaker()"
      [isDisabled]="shouldDisableSpeakerTestButton()"
      class="speaker-test"
      type="secondary"
      [dataTestId]="'test-speaker-button-avcheck'"
    >
      Test Speaker
    </app-button>
  </ng-container>
  }
</mat-expansion-panel>

<ng-template #audioIconContainer>
  <div class="mat-expansion-panel__info-icon-container">
    @if (micCheckService.micFailure() || speakerFailure() ||
    audioVideoCheckService.hasUserMediaFailure()) {
    <app-info-icon
      alt="Red circle exclamation mark error icon"
      role="img"
      title="Audio Selection Error"
    />
    } @else {
    <img
      src="./assets/images/check_circle_green.svg"
      alt="Green circle check"
      role="img"
      title="Audio Selection Success"
    />
    }
  </div>
</ng-template>

<ng-template #micIconContainer>
  <div class="mat-expansion-panel__info-icon-container">
    @if (micCheckService.micFailure() || audioVideoCheckService.hasUserMediaFailure()) {
    <app-info-icon
      alt="Red circle exclamation mark error icon"
      role="img"
      title="Microphone Selection Error"
    />
    } @else {
    <img
      src="./assets/images/check_circle_green.svg"
      alt="Green circle check"
      role="img"
      title="Microphone Selection Success"
    />
    }
  </div>
</ng-template>

<ng-template #speakerIconContainer>
  <div class="mat-expansion-panel__info-icon-container">
    @if (speakerFailure() || audioVideoCheckService.hasUserMediaFailure()) {
    <app-info-icon
      alt="Red circle exclamation mark error icon"
      role="img"
      title="Speaker Selection Error"
    />
    } @else {
    <img
      src="./assets/images/check_circle_green.svg"
      alt="Green circle check"
      role="img"
      title="Speaker Selection Success"
    />
    }
  </div>
</ng-template>
