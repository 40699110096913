import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PackageUserRole } from 'src/app/features/package-users';

import { DevicePackageValidation } from '../../models';
import { DeviceGroupState } from '../state';

export const getDeviceGroupState = createFeatureSelector<DeviceGroupState.State>('deviceGroup');

export const getDeviceCode = createSelector(
  getDeviceGroupState,
  (state: DeviceGroupState.State) => state.deviceCode
);

export const getUnauthorizedDeviceCodes = createSelector(
  getDeviceGroupState,
  (state: DeviceGroupState.State) => state.unauthorizedDeviceCodes
);

export const getUsersOnDevice = createSelector(
  getDeviceGroupState,
  (state: DeviceGroupState.State) => state.usersOnDevice
);

export const getIsParticipantRejoining = createSelector(
  getDeviceGroupState,
  (state: DeviceGroupState.State) => state.isParticipantRejoining
);

export const getVerifiedDeviceCode = createSelector(
  getDeviceGroupState,
  (state: DeviceGroupState.State): DevicePackageValidation =>
    ({
      deviceCode: state.deviceCode,
      isValidForPackage: state.isValidForPackage,
    } as DevicePackageValidation)
);

export const sharedDevice = createSelector(getDeviceGroupState, (state: DeviceGroupState.State) => {
  return (
    state.usersOnDevice?.filter(
      (users) => users.userRoleCode !== PackageUserRole.SIGNINGAGENT.toString()
    ).length > 1
  );
});

export const hasAuthenticatedNonSAUsers = createSelector(
  getDeviceGroupState,
  (state: DeviceGroupState.State) => {
    return (
      state.usersOnDevice?.filter(
        (users) => users.userRoleCode !== PackageUserRole.SIGNINGAGENT.toString()
      ).length > 0
    );
  }
);

export const isUserOnDevice = (packageUserGuid: string) =>
  createSelector(
    getDeviceGroupState,
    (state: DeviceGroupState.State) =>
      state.usersOnDevice?.filter(
        (users) => users.packageUserGuid.toLowerCase() === packageUserGuid?.toLowerCase()
      ).length > 0
  );

export const getRejoinedPackageUsersGuid = createSelector(
  getDeviceGroupState,
  (state: DeviceGroupState.State) => state.rejoiningPackageUserGuids
);

export const getDeviceUsersLoaded = createSelector(
  getDeviceGroupState,
  (state: DeviceGroupState.State) => state.deviceUsersLoaded
);

export const getDeviceUsersLoading = createSelector(
  getDeviceGroupState,
  (state: DeviceGroupState.State) => state.isDeviceUsersLoading
);

export const getIsPinOrMfaComplete = createSelector(
  getDeviceGroupState,
  (state: DeviceGroupState.State) => state.isPinOrMfaComplete
);

export const selectClientHintsForDevice = createSelector(
  getDeviceGroupState,
  (state: DeviceGroupState.State) => state.clientHints
);
