<mat-expansion-panel
  class="mat-expansion-panel"
  data-testid="av-check-video-select"
  [expanded]="true"
  hideToggle
>
  <mat-expansion-panel-header>
    <mat-panel-title data-testid="av-check-video-title">
      <mat-icon class="material-icons-outlined">videocam</mat-icon> Camera
    </mat-panel-title>
    <mat-panel-description>
      <ng-container *ngTemplateOutlet="infoIconContainer" />
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="mat-expansion-panel__body">
    <mat-form-field subscriptSizing="dynamic">
      <mat-label data-testid="av-check-camera-select-label">Select Camera</mat-label>
      <mat-select
        [value]="audioVideoCheckService.selectVideoDevice()?.label || ''"
        [disabled]="!audioVideoCheckService.videoInputDevices().length"
      >
        <mat-option value="" (click)="audioVideoCheckService.clearVideo()">
          No Camera Selected
        </mat-option>
        @for (inputDevice of audioVideoCheckService.videoInputDevices(); track inputDevice) {
        <mat-option
          [value]="inputDevice.label"
          (click)="audioVideoCheckService.selectVideo(inputDevice)"
        >
          {{ inputDevice.label }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <ng-container *ngTemplateOutlet="infoIconContainer" />
  </div>
  @if (audioVideoCheckService.hasUserMediaFailure()) {
  <app-av-check-guide
    [showButton]="audioVideoCheckService.isBrowserDetected"
    [(showDetails)]="showVideoGuideDetails"
  >
    @if (audioVideoCheckService.isChrome) {
    <div>
      <p>
        Hi there! It looks like your camera might be blocked by your chrome browser. Let's get this
        sorted out.
      </p>

      <ul *ngIf="showVideoGuideDetails">
        <li>At the top right, click on the More button (three vertical dots).</li>
        <li>Click on Settings.</li>
        <li>
          Scroll down to Privacy and security and click on Site settings.
          <ul>
            <li>Click on Camera or Microphone.</li>
            <li>
              Here, you can review your blocked and allowed sites. To unblock a site:
              <ul>
                <li>Find the site under the "Blocked" section.</li>
                <li>
                  Click on the trash bin icon to the right of the site to remove it from the blocked
                  list.
                </li>
                <li>
                  Alternatively, click on the site and change the camera or microphone permission.
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    } @else if (audioVideoCheckService.isEdge) {
    <div>
      <p>
        Hi there! It looks like your camera might be blocked by your edge browser. Let's get this
        sorted out.
      </p>

      <ul *ngIf="showVideoGuideDetails">
        <li>Click on the lock icon on the left of the address bar.</li>
        <li>Alternatively, click on the site and change the camera or microphone permission.</li>
        <li>
          If your camera and microphone are still disabled, you may need to reset the site
          permissions:
          <ul>
            <li>While in your session link, click the lock icon to the left of the address bar.</li>
            <li>Click Site Permissions next to the gear icon.</li>
            <li>At the bottom of the settings page, click Reset permissions.</li>
            <li>
              Refresh the page. You'll be asked again to allow access to your camera and microphone
            </li>
          </ul>
        </li>
        <li>
          For Edge on mobile or tablet:
          <ul>
            <li>Open your device's Settings app.</li>
            <li>Go to Apps & App permissions.</li>
            <li>Select Edge from your list of installed apps.</li>
            <li>Open Permissions and ensure your camera and microphone are toggled on.</li>
            <li>Quit then restart your Edge app.</li>
          </ul>
        </li>
        <li>
          If you're still having trouble, make sure that the camera access is turned on in your
          Windows settings:
          <ul>
            <li>
              Select Start > Settings > Privacy & security > Camera, then make sure Camera access is
              turned on.
            </li>
            <li>
              Under Allow access to the camera on this device, make sure Let apps access your camera
              is turned on.
            </li>
            <li>Find the app you want, then turn on camera access for that app.</li>
          </ul>
        </li>
        <li>
          Remember to only grant camera access to websites and apps you trust, as this is important
          for your privacy and security. If you continue to experience issues, you may need to check
          for broader permissions in your Windows System Preferences under Security & Privacy >
          Privacy > Camera, and ensure Edge is allowed to use the camera.
        </li>
      </ul>
    </div>
    } @else if (audioVideoCheckService.isSafari) {
    <div>
      <p>
        Hi there! It looks like your camera might be blocked by your safari browser. Let's get this
        sorted out.
      </p>

      <ul *ngIf="showVideoGuideDetails">
        <li>Tap on Camera/Microphone.</li>
        <li>Choose Allow to enable camera access for Safari.</li>
        <li>
          If you're using Safari on a Mac:
          <ul>
            <li>Click on “Safari” in the menu bar at the top of your screen.</li>
            <li>From the dropdown menu, select “Preferences.”</li>
            <li>In the Preferences window, click on the “Websites” tab.</li>
            <li>On the left sidebar, click on “Camera” to view camera-related settings.</li>
            <li>
              On the right side, you'll see a list of websites that have requested camera access.
              Here, you can allow or deny access to the camera for each website.
            </li>
          </ul>
        </li>
        <li>
          If you don't see the Camera option, make sure you're running macOS Mojave or later, as
          earlier versions do not support these settings. Also, ensure that your Mac is up to date
          with the latest software updates, which can contain stability fixes that resolve issues
          like blocked cameras.
        </li>
        <li>
          Remember to only grant camera access to websites you trust, as this is important for your
          privacy and security. If you continue to experience issues, you may need to check for
          broader permissions in your Mac's System Preferences under Security & Privacy > Privacy >
          Camera, and ensure Safari is allowed to use the camera.
        </li>
      </ul>
    </div>
    } @else {
    <div>
      <p>
        Hi there! It looks like your camera might be blocked by your browser. Let's get this sorted
        out. Try going to your browsers privacy settings to allow your camera.
      </p>
    </div>
    }
  </app-av-check-guide>
  }
</mat-expansion-panel>

<ng-template #infoIconContainer>
  <div class="mat-expansion-panel__info-icon-container">
    @if (audioVideoCheckService.hasVideoSelectionError()) {
    <img
      src="./assets/images/check_circle_green.svg"
      alt="Green circle check"
      role="img"
      title="Camera Selection Success"
    />
    } @else {
    <app-info-icon
      alt="Red circle exclamation mark error icon"
      role="img"
      title="Camera Selection Error"
    />
    }
  </div>
</ng-template>
