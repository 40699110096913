import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { DefaultProjectorFn, MemoizedSelector, select, Store } from '@ngrx/store';

import { saveAs } from 'file-saver';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { DeviceGroupSelectors } from 'src/app/features/device-group';
import { Feature } from 'src/app/features/feature-management/models';
import { FeatureManagementService } from 'src/app/features/feature-management/services';
import { ModalsActions, ModalsSelectors } from 'src/app/features/modals';
import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { ExceptionType } from 'src/app/features/notifications/models';
import { PackageUserRole } from 'src/app/features/package-users';
import { PackageUsersSelectors } from 'src/app/features/package-users/store';
import { PackagesActions, PackagesSelectors } from 'src/app/features/packages';
import { ProductType } from 'src/app/features/packages/models';
import { ParticipantVerificationSelectors } from 'src/app/features/participant-verification/store';
import { SignalRActions } from 'src/app/features/signal-r';
import { WizardSelectors } from 'src/app/features/wizard';
import { RootStoreState } from 'src/app/store';
import { v4 as uuid } from 'uuid';

import {
  RecordingCouldNotStartModalComponent,
  StartingRecordingModalComponent,
  WaitingOnOtherParticipantsModalComponent,
} from '../../components';
import { PostArchiveRequest, PutArchiveRequest } from '../../models';
import { VideoService } from '../../services';
import { VideoActions } from '../actions';
import { VideoSelectors } from '../selectors';

@Injectable()
export class VideoEffects {
  public activePackageUserGuidSelector: MemoizedSelector<
    object,
    string,
    DefaultProjectorFn<string>
  >;

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootStoreState.State>,
    private readonly videoService: VideoService,
    private readonly featureManagementService: FeatureManagementService
  ) {
    this.activePackageUserGuidSelector = PackageUsersSelectors.getActivePackageUserGuid;
  }

  createVideoSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.CreateVideoSession),
      concatLatestFrom(() => [
        this.store.select(PackageUsersSelectors.getActivePackageUserGuid),
        this.store.select(VideoSelectors.getIsVideoSessionCreated),
      ]),
      switchMap(([action, packageUserGuid, isVideoSessionCreated]) => {
        const isChimeVideoEnabled = this.featureManagementService.getIsFeatureEnabledWithCaching(
          Feature.ChimeVideo
        );

        if (isChimeVideoEnabled) {
          return [];
        }

        if (isVideoSessionCreated) {
          return [];
        }

        return this.videoService.postVideoSession(packageUserGuid).pipe(
          switchMap(() => [VideoActions.CreateVideoSessionSuccess()]),
          catchError((error) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: error,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to Create Video Session',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        );
      })
    )
  );

  createVideoSessionIfSigningAgent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.CreateVideoSessionIfSigningAgent),
      concatLatestFrom(() => [this.store.select(WizardSelectors.getActiveWizardUser)]),
      filter(([_, activeUser]) => activeUser?.userRoleCode === PackageUserRole.SIGNINGAGENT),
      map(([_, __]) => VideoActions.CreateVideoSession())
    )
  );

  getVideoSessionInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.GetVideoSessionInfo),
      concatLatestFrom(() => [this.store.select(VideoSelectors.getIsVideoSessionInfoLoaded)]),
      switchMap(([action, isVideoSessionInfoLoaded]) => {
        const isChimeVideoEnabled = this.featureManagementService.getIsFeatureEnabledWithCaching(
          Feature.ChimeVideo
        );

        if (isChimeVideoEnabled) {
          return [];
        }

        if (isVideoSessionInfoLoaded) {
          return [];
        }

        return this.videoService.getVideoSessionInfo().pipe(
          switchMap((payload) => [VideoActions.SetVideoSessionInfo({ payload })]),
          catchError((error) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: error,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to Get Video Session Info',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        );
      })
    )
  );

  startArchive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.StartArchive),
      concatLatestFrom(() => [
        this.store.select(VideoSelectors.getVideoSessionKey),
        this.store.pipe(select(PackageUsersSelectors.getIsActivePackageUserSigningAgent)),
      ]),
      switchMap(([action, videoSessionKey, isSigningAgent]) => {
        if (!isSigningAgent) {
          return [];
        }

        return this.videoService.postStartArchive({ videoSessionKey } as PostArchiveRequest).pipe(
          switchMap(() => []),
          catchError((error) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: error,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to Start Archive',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        );
      })
    )
  );

  stopArchive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.StopArchive),
      concatLatestFrom(() => [this.store.select(VideoSelectors.getVideoSessionKey)]),
      switchMap(([action, videoSessionKey]) => {
        return this.videoService.putStopArchive({ videoSessionKey } as PutArchiveRequest).pipe(
          switchMap(() => [VideoActions.StopArchiveSuccess()]),
          catchError((error) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: error,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to Stop Archive',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        );
      })
    )
  );

  showStartingRecordingModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.ShowStartingRecordingModal),
      map((action) =>
        ModalsActions.SetStandaloneModalComponent({
          payload: {
            component: StartingRecordingModalComponent,
          },
        })
      )
    )
  );

  hideStartingRecordModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.HideStartingRecordingModal),
      map((action) => ModalsActions.ClearModalComponent())
    )
  );

  setVideoConnectionIssue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.SetVideoConnectionIssue),
      concatMap((action) =>
        of(action).pipe(withLatestFrom(this.store.select(DeviceGroupSelectors.getDeviceCode)))
      ),
      switchMap(([_, deviceCode]) => {
        return this.videoService.postVideoConnectionIssue(deviceCode).pipe(
          switchMap(() => of(VideoActions.SendVideoConnectionIssueSuccessful())),
          catchError((error) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: error,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to Post Video Connection issue',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        );
      })
    )
  );

  setVideoVisibilityIssue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.SetVideoVisibilityIssue),
      concatMap((action) =>
        of(action).pipe(withLatestFrom(this.store.select(DeviceGroupSelectors.getDeviceCode)))
      ),
      switchMap(([_, deviceCode]) => {
        return this.videoService.postVideoVisibilityIssue(deviceCode).pipe(
          switchMap(() => of(VideoActions.SendVideoVisibilityIssueSuccessful())),
          catchError((error) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: error,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to Post Video Visibility issue',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        );
      })
    )
  );

  getAreAllVideoStreamsConnected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.GetAreAllVideoStreamsConnected),
      withLatestFrom(this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid))),
      filter(([_, packageUserGuid]) => !!packageUserGuid),
      exhaustMap(([_, packageUserGuid]) =>
        this.videoService.getAreAllStreamsConnected(packageUserGuid).pipe(
          map((areAllVideoStreamsConnected: boolean) =>
            VideoActions.SetAreAllVideoStreamsConnected({
              payload: areAllVideoStreamsConnected,
            })
          ),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed get video stream connected information',
                },
              })
            )
          )
        )
      )
    )
  );

  showWaitingOnOtherParticipantsModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.ShowWaitingOnOtherParticipantsModal),
      // TODO Story #62143 : Remove SigningRoomActionBlocking Feature flag code
      filter(
        () =>
          !this.featureManagementService.getIsFeatureEnabledWithCaching(
            Feature.SigningRoomActionBlocking
          )
      ),
      switchMap(() => [
        ModalsActions.SetStandaloneModalComponent({
          payload: {
            component: WaitingOnOtherParticipantsModalComponent,
          },
        }),
      ])
    )
  );

  hideWaitingOnOtherParticipantsModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.HideWaitingOnOtherParticipantsModal),
      filter(
        () =>
          !this.featureManagementService.getIsFeatureEnabledWithCaching(
            Feature.SigningRoomActionBlocking
          )
      ),
      map((action) => ModalsActions.ClearModalComponent())
    )
  );

  handleStartArchiveFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.HandleStartArchiveFailed),
      concatLatestFrom(() => [
        this.store.pipe(select(PackageUsersSelectors.getIsActivePackageUserSigningAgent)),
      ]),
      switchMap(([action, isSigningAgent]) => {
        if (!isSigningAgent) {
          return [];
        } else {
          return [
            VideoActions.HideStartingRecordingModal(),
            VideoActions.ShowRecordingFailedToStartModal(),
          ];
        }
      })
    )
  );

  showRecordingFailedToStartModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.ShowRecordingFailedToStartModal),
      map((action) =>
        ModalsActions.SetStandaloneModalComponent({
          payload: {
            component: RecordingCouldNotStartModalComponent,
            shouldFade: true,
          },
        })
      )
    )
  );

  sendVideoStreamConnected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.SendVideoStreamConnected),
      concatMap((action) =>
        of(action).pipe(withLatestFrom(this.store.select(DeviceGroupSelectors.getDeviceCode)))
      ),
      switchMap(([{ payload }, deviceCode]) => {
        return this.videoService.postStreamConnected(payload.videoStreamId, deviceCode).pipe(
          switchMap(() => []),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to send video stream created',
                },
              })
            )
          )
        );
      })
    )
  );

  sendVideoStreamDestroyed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.SendVideoStreamDestroyed),
      concatMap((action) =>
        of(action).pipe(withLatestFrom(this.store.select(DeviceGroupSelectors.getDeviceCode)))
      ),
      switchMap(([{ payload }]) => {
        return this.videoService.putStreamDisconnected(payload.videoStreamId).pipe(
          switchMap(() => []),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to send video stream destroyed',
                },
              })
            )
          )
        );
      })
    )
  );

  sendVideoEnabled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.SendVideoEnabled),
      concatMap((action) =>
        of(action).pipe(withLatestFrom(this.store.select(DeviceGroupSelectors.getDeviceCode)))
      ),
      switchMap(([{ payload }]) => {
        return this.videoService.putStreamEnabled(payload.videoStreamId).pipe(
          switchMap(() => []),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to send video stream destroyed',
                },
              })
            )
          )
        );
      })
    )
  );

  receivedVideoStreamDisconnected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.ReceivedVideoStreamDisconnected),
      withLatestFrom(
        this.store.pipe(select(VideoSelectors.getVideoConnection)),
        this.store.pipe(select(VideoSelectors.getStopVideo)),
        this.store.pipe(select(ParticipantVerificationSelectors.doesIdVerificationExist)),
        this.store.pipe(select(PackagesSelectors.getProductType))
      ),
      filter(
        ([_, videoConnection, stopVideo, isParticipantIdologyValid, productType]) =>
          videoConnection.isConnected &&
          !stopVideo &&
          (isParticipantIdologyValid || productType === ProductType.KRON)
      ),
      switchMap(() => [VideoActions.ShowWaitingOnOtherParticipantsModal()])
    )
  );

  receivedVideoStreamConnected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.ReceivedVideoStreamConnected),
      withLatestFrom(
        this.store.pipe(select(VideoSelectors.getAreAllVideoStreamsConnected)),
        this.store.pipe(select(ParticipantVerificationSelectors.doesIdVerificationExist)),
        this.store.pipe(select(PackagesSelectors.getProductType))
      ),
      filter(
        ([_, areAllVideoStreamsConnected, isParticipantIdologyValid, productType]) =>
          areAllVideoStreamsConnected &&
          (isParticipantIdologyValid || productType === ProductType.KRON)
      ),
      switchMap(() => [VideoActions.HideWaitingOnOtherParticipantsModal()])
    )
  );

  receivedVideoStreamEnabled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.ReceivedVideoStreamEnabled),
      withLatestFrom(this.store.pipe(select(VideoSelectors.getAreAllVideoStreamsConnected))),
      filter(([_, areAllVideoStreamsConnected]) => areAllVideoStreamsConnected),
      switchMap(() => [VideoActions.HideWaitingOnOtherParticipantsModal()])
    )
  );

  setIsVideoDownloading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.FetchVideo),
      map((action) =>
        VideoActions.SetIsVideoDownloading({
          payload: {
            videoArchiveId: action.payload.videoArchiveId,
            isDownloading: true,
          },
        })
      )
    )
  );

  fetchVideo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.FetchVideo),
      withLatestFrom(this.store.pipe(select(PackagesSelectors.getActivePackageGuid))),
      switchMap(([action, packageGuid]) => {
        return this.videoService.getVideoLink(packageGuid, action.payload.videoArchiveId).pipe(
          tap((payload) => {
            saveAs(payload.uri, 'archive.mp4');
          }),
          switchMap((payload) => [
            VideoActions.AuditDownloadVideo({
              payload: {
                VideoArchiveId: payload.videoArchiveId,
                VideoUri: payload.uri,
              },
            }),
            VideoActions.SetIsVideoDownloading({
              payload: {
                videoArchiveId: payload.videoArchiveId,
                isDownloading: false,
              },
            }),
          ]),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to download video',
                  exceptionType: ExceptionType.CannotProceed,
                },
              }),
              VideoActions.SetIsVideoDownloading({
                payload: {
                  videoArchiveId: action.payload.videoArchiveId,
                  isDownloading: false,
                },
              })
            )
          )
        );
      })
    )
  );

  auditDownloadVideo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.AuditDownloadVideo),
      withLatestFrom(this.store.pipe(select(PackagesSelectors.getActivePackageGuid))),
      switchMap(([action, packageGuid]) => {
        return this.videoService
          .auditVideoDownloaded(packageGuid, action.payload.VideoArchiveId, action.payload.VideoUri)
          .pipe(
            switchMap((payload) => [VideoActions.AuditDownloadVideoSuccessful()]),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to retrieve Audit Video Download details',
                    exceptionType: ExceptionType.CannotProceed,
                  },
                })
              )
            )
          );
      })
    )
  );

  fetchDownloadVideoArchiveIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.FetchDownloadVideoArchiveIds),
      withLatestFrom(this.store.pipe(select(PackagesSelectors.getActivePackageGuid))),
      switchMap(([action, packageGuid]) => {
        return this.videoService.getVideoArchiveIds(packageGuid).pipe(
          switchMap((payload) => [
            VideoActions.FetchDownloadVideoArchiveIdsSuccessful({ payload }),
          ]),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to video archive ids for download',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        );
      })
    )
  );

  getVideoArchiveStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.GetVideoArchiveStatus),
      concatLatestFrom(() => [this.store.select(VideoSelectors.getVideoSessionKey)]),
      switchMap(([action, videoSessionKey]) => {
        return this.videoService.getArchiveStatus(videoSessionKey).pipe(
          switchMap((status) => [
            VideoActions.GetVideoArchiveStatusSuccessful({
              payload: { status },
            }),
          ]),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get video arvhive status',
                  exceptionType: ExceptionType.None,
                },
              }),
              VideoActions.GetVideoArchiveStatusSuccessful({
                payload: { status: true },
              })
            )
          )
        );
      })
    )
  );

  getVideoArchiveStatusSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VideoActions.GetVideoArchiveStatusSuccessful),
      delay(3000),
      switchMap((action) => {
        if (!action.payload.status) {
          return [VideoActions.GetVideoArchiveStatus()];
        }
        return [];
      })
    )
  );

  configureListeners$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignalRActions.SignalRRoomJoined),
        map(() => {
          this.videoService.configureListeners();
        })
      ),
    { dispatch: false }
  );

  stopOptOutArchive$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PackagesActions.OptOutSuccess),
      concatLatestFrom(() => [
        this.store.select(ModalsSelectors.selectShouldStopArchiveOnOrderCancellation),
      ]),
      filter(([_, shouldStopArchive]) => shouldStopArchive),
      map(() => VideoActions.StopArchive())
    );
  });
}
