import { createAction, props } from '@ngrx/store';

export const AudioVideoCheckStarted = createAction('[Audio Video Check] Audio Video Check Started');

export const SaveSessionCapableSuccessful = createAction(
  '[Audio Video Check] Audit Log save successful'
);

export const SaveSessionCapableFailed = createAction('[Audio Video Check] Audit Log save failed');

export const SaveAuditSessionCapable = createAction('[Audio Video Check] Save Session Capable');

export const AudioVideoCheckOpened = createAction('[Audio Video Check] Opened');

export const GetUserMedia = createAction('[Audio Video Check] GetUserMedia');
export const GetUserMediaSuccess = createAction('[Audio Video Check] Get User Media Success');
export const GetUserMediaFailure = createAction(
  '[Audio Video Check] Get User Media Failure',
  props<{ error: Error }>()
);

export const EnumerateDevicesSuccess = createAction(
  '[Audio Video Check] Enumerate Devices Success',
  props<{
    devices: MediaDeviceInfo[];
  }>()
);

export const SetMediaStream = createAction(
  '[Audo Video Check] Set MediaStream',
  props<{ mediaStream: MediaStream }>()
);

export const SelectMicrophone = createAction(
  '[Audio Select] Select Microphone',
  props<{ microphone: MediaDeviceInfo }>()
);

export const ClearMicrophone = createAction('[Audio Select] Clear Microphone');
export const ClearSpeaker = createAction('[Audio Select] Clear Speaker');
export const ClearVideo = createAction('[Audio Select] Clear Video');

export const SelectSpeaker = createAction(
  '[Audio Select] Select Speaker',
  props<{ speaker: MediaDeviceInfo }>()
);

export const SelectVideo = createAction(
  '[Audo Video Check] Select Video',
  props<{ video: MediaDeviceInfo }>()
);
