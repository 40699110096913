export interface State {
  isAudioVideoCheckComplete: boolean;
  audioVideoDevices: MediaDeviceInfo[];
  mediaStream: MediaStream | null;
  error: string | null;
}

export const initialState: State = {
  isAudioVideoCheckComplete: false,
  audioVideoDevices: [],
  mediaStream: null,
  error: null,
};
