import { Action, createReducer, on } from '@ngrx/store';

import { AudioVideoCheckActions } from '../actions';
import { AudioVideoCheckState } from '../state';

const reducer = createReducer(
  AudioVideoCheckState.initialState,
  on(AudioVideoCheckActions.SaveSessionCapableSuccessful, (state: AudioVideoCheckState.State) => ({
    ...state,
    isAudioVideoCheckComplete: true,
  })),
  on(
    AudioVideoCheckActions.EnumerateDevicesSuccess,
    (state, { devices }): AudioVideoCheckState.State => ({
      ...state,
      error: null,
      audioVideoDevices: devices,
    })
  ),
  on(
    AudioVideoCheckActions.GetUserMediaFailure,
    (state, { error }): AudioVideoCheckState.State => ({
      ...state,
      audioVideoDevices: [],
      error: String(error),
    })
  ),
  on(
    AudioVideoCheckActions.SetMediaStream,
    (state, { mediaStream }): AudioVideoCheckState.State => ({
      ...state,
      mediaStream,
    })
  )
);

export function audioVideoCheckReducer(
  state: AudioVideoCheckState.State | undefined,
  action: Action
) {
  return reducer(state, action);
}
