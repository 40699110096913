<app-audio-video-check-v2 [useDefaultActions]="false" #audioVideoCheck>
  <app-button-container>
    <app-button
      (click)="cancelAudioVideoSelections()"
      [dataTestId]="'device-settings-cancel'"
      type="secondary"
    >
      Cancel
    </app-button>
    <app-button
      (click)="continue()"
      [dataTestId]="'device-settings-apply'"
      [isDisabled]="!areRequiredDevicesSelectedAndTested()"
      type="primary"
    >
      Apply Changes
    </app-button>
  </app-button-container>
</app-audio-video-check-v2>
