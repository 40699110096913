import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  PackageUser,
  PackageUserRole,
  PackageUsersSelectors,
} from 'src/app/features/package-users';
import { VideoArchiveIdsResponse } from 'src/app/features/video/models';
import { VideoActions, VideoSelectors } from 'src/app/features/video/store';

import { RootStoreState } from 'src/app/store';


@Component({
  selector: 'app-download-video-button',
  templateUrl: './download-video-button.component.html',
  styleUrls: ['./download-video-button.component.scss'],
})
export class DownloadVideoButtonComponent implements OnInit {
  VideoDownloadRolesEnums = [PackageUserRole.SIGNINGAGENT];
  videoDownloadArchiveIds$: Observable<VideoArchiveIdsResponse>;
  videoArchiveDownloadStatus$: Observable<boolean>;
  user$: Observable<PackageUser>;

  constructor(
    private readonly store: Store<RootStoreState.State>
  ) {}

  ngOnInit(): void {
    this.user$ = this.store.select(PackageUsersSelectors.getActivePackageUser);
    this.videoDownloadArchiveIds$ = this.store.select(VideoSelectors.getDownloadVideoArchiveIds);
    this.videoArchiveDownloadStatus$ = this.store.select(VideoSelectors.getVideoArchiveStatus);
    this.onInitBuildVideoLinks();
  }
  
  onVideoButtonClick(videoArchiveId: number): void {
    this.store.dispatch(VideoActions.FetchVideo({ payload: { videoArchiveId } }));
  }

  onInitBuildVideoLinks(): void {
    this.user$.subscribe((user) => {
      if (this.isUserSigningAgent(user)) {
        this.store.dispatch(VideoActions.GetVideoArchiveStatus());
        this.store.dispatch(VideoActions.FetchDownloadVideoArchiveIds());
      }
    });
  }

  isUserSigningAgent(user: PackageUser): boolean {
    return this.VideoDownloadRolesEnums.indexOf(user.userRoleCode) !== -1;
  }
}
