import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ToastrModule } from 'ngx-toastr';
import { ActionBlockingSnackbarComponent } from 'src/app/features/notifications/components/action-blocking-snackbar-notification/action-blocking-snackbar.component';
import { SharedModule } from 'src/app/features/shared';

import { LeaveSiteWarningComponent, ToastModalComponent } from './components';
import { DEFAULT_TOASTR_OPTIONS } from './notifications.constants';
import { ToastService } from './services';
import { NotificationsEffects, NotificationsReducers } from './store';

@NgModule({
  declarations: [
    ToastModalComponent,
    LeaveSiteWarningComponent,
    ActionBlockingSnackbarComponent,
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot({
      ...DEFAULT_TOASTR_OPTIONS,
    }),
    StoreModule.forFeature('notifications', NotificationsReducers.notificationsReducer),
    EffectsModule.forFeature([NotificationsEffects.NotificationsEffects]),
    SharedModule,
  ],
  exports: [LeaveSiteWarningComponent],
  providers: [ToastService],
})
export class NotificationsModule {}
