import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

import { of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { AuditLogsActions } from 'src/app/features/audit-logs';
import { AudioVideoCheckSelectors } from 'src/app/features/av-check/store/selectors';
import { DeviceGroupActions, DeviceGroupSelectors } from 'src/app/features/device-group';
import { DeviceGroupService } from 'src/app/features/device-group/services';
import { EndorsementsCreationActions } from 'src/app/features/endorsements-creation/store';
import { EndorsementsService } from 'src/app/features/endorsements/services';
import { Feature } from 'src/app/features/feature-management/models';
import { FeatureManagementService } from 'src/app/features/feature-management/services';
import { KronActions } from 'src/app/features/kron/store';
import { ModalsActions, ModalSize } from 'src/app/features/modals';
import { MsalAuthSelectors } from 'src/app/features/msal-auth';
import { MultiFactorActions } from 'src/app/features/multi-factor/store/actions';
import {
  ExceptionType,
  NotificationsActions,
  NotificationType,
} from 'src/app/features/notifications';
import {
  PackageUserRole,
  PackageUsersActions,
  PackageUsersSelectors,
  PackageUsersService,
} from 'src/app/features/package-users';
import { PackagesSelectors, ProductType } from 'src/app/features/packages';
import { PRODUCT_TYPE_ROUTE_MAP } from 'src/app/features/packages/packages.constants';
import { PinValidationActions } from 'src/app/features/pin-validation';
import { RonActions } from 'src/app/features/ron';
import { SignalRActions } from 'src/app/features/signal-r';
import { WizardActions, WizardSelectors } from 'src/app/features/wizard';
import { v4 as uuid } from 'uuid';
import { EndorsementsSelectors } from '../../../endorsements';

import { SwitchDevicesConfirmationModalComponent } from '../../components';
import { NextStep } from '../../models';
import { CheckInStatusService } from '../../services';
import { WaitingRoomService } from '../../services/waiting-room.service';
import { CheckInActions } from '../actions';
import { CheckInSelectors } from '../selectors';

@Injectable()
export class CheckInEffects {
  beginCheckInForAllUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInActions.BeginCheckInForAllUsers),
      concatLatestFrom(() => [
        this.store.pipe(select(PackagesSelectors.getProductType)),
        this.store.pipe(select(PackageUsersSelectors.getSigningAgent)),
        this.store.pipe(select(PackageUsersSelectors.getNonSigningAgents)),
      ]),
      filter(
        ([_, productType, __, ___]) =>
          productType === ProductType.Hybrid || productType === ProductType.IPEN
      ),
      switchMap(([_, __, signingAgent, nonSigningAgents]) => {
        const signingAgentGuids = !!signingAgent ? [signingAgent.packageUserGuid] : [];
        const nonSigningAgentGuids = nonSigningAgents.map((pu) => pu.packageUserGuid);
        return [
          CheckInActions.SetUserCheckInSequence({
            payload: [...signingAgentGuids, ...nonSigningAgentGuids],
          }),
          CheckInActions.BeginCheckIn(),
        ];
      })
    )
  );

  beginCheckIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInActions.BeginCheckIn),
      concatLatestFrom(() => [
        this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
        this.store.pipe(select(CheckInSelectors.getUserCheckInSequence)),
      ]),
      switchMap(([_, packageGuid, usersToCheckIn]) =>
        this.checkInStatusService.getPackageCheckInStatus(packageGuid).pipe(
          switchMap((users) => {
            const nextUser = usersToCheckIn
              .map((guid) => users.find(({ packageUserGuid }) => guid === packageUserGuid))
              .find((pu) => !pu.checkInStatus?.isCompleted);

            return [
              CheckInActions.PromptUserForAuthentication({
                payload: {
                  participant: nextUser,
                },
              }),
            ];
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to fetch user check-in statuses',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        )
      )
    )
  );

  beginRejoin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInActions.BeginRejoin),
      concatLatestFrom(() => [
        this.store.pipe(select(PackageUsersSelectors.getPackageUsers)),
        this.store.pipe(select(CheckInSelectors.getUserCheckInSequence)),
      ]),
      map(([_, users, userCheckInSequence]) => {
        const nextUser = users.find(
          ({ packageUserGuid }) => packageUserGuid === userCheckInSequence[0]
        );
        return CheckInActions.PromptUserForAuthentication({
          payload: {
            participant: nextUser,
          },
        });
      })
    )
  );

  checkForDeviceSwitch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInActions.CheckForDeviceSwitch),
      concatLatestFrom((action) => [
        this.store.select(PackageUsersSelectors.getPackageUser(action.payload.packageUserGuid)),
        this.store.pipe(select(DeviceGroupSelectors.getUsersOnDevice)),
        this.store.pipe(select(CheckInSelectors.getUserCheckInSequence)),
      ]),
      map(([action, user, deviceUsers, checkInSequence]) => {
        const isUserAlreadySelected = checkInSequence.includes(action.payload.packageUserGuid);
        const isCheckingIn = user.checkInStatus?.isStarted && !user.checkInStatus?.isCompleted;
        const isSigningAgentRejoining =
          user.userRoleCode === PackageUserRole.SIGNINGAGENT && user.checkInStatus?.isCompleted;

        const isSwitchingDevices =
          !isUserAlreadySelected &&
          (isCheckingIn || isSigningAgentRejoining) &&
          !deviceUsers.some((u) => u.packageUserGuid === action.payload.packageUserGuid);

        return isSwitchingDevices
          ? ModalsActions.SetStandaloneModalComponent({
              payload: {
                component: SwitchDevicesConfirmationModalComponent,
                componentData: {
                  packageUserGuid: action.payload.packageUserGuid,
                  isCheckInComplete: isSigningAgentRejoining,
                  isInWaitingRoom: action.payload.isInWaitingRoom
                },
                modalSize: ModalSize.small,
              },
            })
          : CheckInActions.ToggleUserCheckInSequence({ payload: action.payload });
      })
    )
  );

  completeCheckInWizard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInActions.CompleteCheckInWizard),
      concatLatestFrom(() => [
        this.store.pipe(select(WizardSelectors.getActiveWizardUserGuid)),
        this.store.pipe(select(PackagesSelectors.getProductType)),
        this.store.pipe(select(CheckInSelectors.getHasWaitingRoomBeenViewedByAllParticipants)),
      ]),
      tap(([_, __, productType, waitingRoomViewed]) => {
        let nextPage = 'check-in-complete';

        // Back button case, all conditions are met to join signing skip right to the waiting room
        // To give check in complete actions a chance to complete before entering the signing
        if (waitingRoomViewed) {
          nextPage = 'waiting-room';
        }

        this.router.navigate([`/${PRODUCT_TYPE_ROUTE_MAP.get(productType)}/${nextPage}`], {
          queryParamsHandling: 'preserve',
          replaceUrl: true,
        });
      }),
      switchMap(([_, packageUserGuid, __]) => [
        AuditLogsActions.SaveReadyUpEvent({
          payload: { packageUserGuid },
        }),
        CheckInActions.SetUserCheckInComplete({ payload: { packageUserGuid } }),
        CheckInActions.ActiveSessionCheckInCompletion(),
        EndorsementsCreationActions.CompleteEndorsementsCreation(),
        DeviceGroupActions.SaveToDeviceGroup({ payload: { packageUserGuid: packageUserGuid }, }),
        WizardActions.ClearActiveWizardUser(),
      ])
    )
  );

  signSystemEndorsements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInActions.SetUserCheckInComplete),
      filter(
        () =>
          this.featureManagementService.getIsFeatureEnabledWithCaching(Feature.NeaSystemFields) ===
          true
      ),
      switchMap((_) => {
        return this.endorsementService.signSystemEndorsements().pipe(
          switchMap(() => []),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to handle system fields',
                  exceptionType: ExceptionType.DisplayMessage,
                },
              })
            )
          )
        );
      })
    )
  );

  activeSessionCheckInCompletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInActions.ActiveSessionCheckInCompletion),
      switchMap((_) => {
        return this.checkInStatusService.activeSessionCheckInCompletion().pipe(
          switchMap(() => []),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to complete check-in',
                  exceptionType: ExceptionType.DisplayMessage,
                },
              })
            )
          )
        );
      })
    )
  );

  continueCheckIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInActions.ContinueCheckIn),
      concatLatestFrom(() => [
        this.store.pipe(select(CheckInSelectors.getNextCheckInUser)),
        this.store.pipe(select(PackageUsersSelectors.getPackageUsers)),
      ]),
      switchMap(([_, nextUserGuid, packageUsers]) => [
        WizardActions.SetActiveWizardUser({
          payload: { user: packageUsers.find((u) => u.packageUserGuid === nextUserGuid) },
        }),
        CheckInActions.RedirectToCheckInWizard(),
      ])
    )
  );

  continueCheckInOrRejoin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInActions.ContinueCheckInOrRejoin),
      concatLatestFrom(() => [this.store.pipe(select(CheckInSelectors.getNextStep))]),
      map(([_, nextStep]) => {
        const ACTION_MAP = new Map<NextStep, TypedAction<any>>([
          [NextStep.CheckIn, CheckInActions.ContinueCheckIn()],
          [NextStep.RejoinWaitingRoom, CheckInActions.ContinueRejoin()],
          [NextStep.SigningRoom, CheckInActions.RedirectToSigningRoom()],
          [NextStep.WaitingRoom, CheckInActions.RedirectToWaitingRoom()],
        ]);
        return ACTION_MAP.get(nextStep);
      })
    )
  );

  continueRejoin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInActions.ContinueRejoin),
      concatLatestFrom(() => [
        this.store.pipe(select(CheckInSelectors.getUserCheckInSequence)),
        this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
      ]),
      switchMap(([_, userCheckInSequence, deviceCode]) => {
        const nextUserGuid = userCheckInSequence[0];
        return this.deviceGroupService.rejoin(nextUserGuid, deviceCode).pipe(
          switchMap(() => [
            DeviceGroupActions.SetParticipantRejoining({
              payload: {
                isParticipantRejoining: true,
                packageUserGuid: nextUserGuid,
              },
            }),
            SignalRActions.AttemptSignalRHubConnection({
              payload: {
                packageUserGuid: nextUserGuid,
              },
            }),
            PackageUsersActions.SetActivePackageUserGuid({
              payload: { activePackageUserGuid: nextUserGuid },
            }),
            RonActions.EnterRejoinWaitingLobby(),
          ]),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to rejoin user',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        );
      })
    )
  );

  deviceChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceGroupActions.DeviceCodeChanged),
      map(() => CheckInActions.ResetState())
    )
  );

  enterSigningRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInActions.EnterSigningRoom),
      concatLatestFrom(() => [this.store.pipe(select(PackagesSelectors.getProductType))]),
      map(([_, productType]) => {
        const MAP = new Map<ProductType, TypedAction<any>>([
          [ProductType.RemoteSigning, RonActions.EnterSigningRoom()],
          [ProductType.KRON, KronActions.EnterSigningRoom()],
        ]);
        return MAP.get(productType);
      })
    )
  );

  promptUserForAuthentication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInActions.PromptUserForAuthentication),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PackagesSelectors.getProductType)),
            this.store.pipe(select(MsalAuthSelectors.getUserGuid))
          )
        )
      ),
      switchMap(([action, productType, loggedInUserGuid]) => {
        const participant = action.payload.participant;
        const isUserLoggedIn =
          productType === ProductType.KRON &&
          loggedInUserGuid.toLowerCase() === participant.packageUserGuid.toLowerCase();

        if (isUserLoggedIn || participant.userRoleCode === PackageUserRole.SIGNINGAGENT) {
          return [
            DeviceGroupActions.SaveToDeviceGroup({
              payload: {
                packageUserGuid: participant.packageUserGuid,
              },
            }),
            CheckInActions.RedirectToAvCheckIfRequired(),
          ];
        }

        if (participant.isMissingVerificationType) {
          return [CheckInActions.RedirectToAvCheckIfRequired()];
        }

        if (participant.isMfaRequired) {
          return [
            MultiFactorActions.StartMultiFactorChallengeFromModalComponent({
              payload: {
                packageUserGuid: participant.packageUserGuid,
              },
            }),
          ];
        }

        return [
          PinValidationActions.StartPinChallenge({
            payload: {
              participant: participant,
              onSuccessAction: CheckInActions.RedirectToAvCheckIfRequired(),
            },
          }),
        ];
      })
    )
  );

  redirectToAvCheckIfRequired$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInActions.RedirectToAvCheckIfRequired),
      concatLatestFrom(() => [
        this.store.pipe(select(AudioVideoCheckSelectors.getIsAudioVideoCheckComplete)),
        this.store.pipe(select(PackagesSelectors.isRONOrKRON)),
      ]),
      map(([_, isAVCheckComplete, isRonOrKron]) =>
        isRonOrKron && !isAVCheckComplete
          ? CheckInActions.RedirectToAudioVideoCheck()
          : CheckInActions.ContinueCheckInOrRejoin()
      )
    )
  );

  redirectToProductRoute$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CheckInActions.RedirectToProductRoute),
        concatLatestFrom(() => [this.store.pipe(select(PackagesSelectors.getProductType))]),
        tap(([action, productType]) => {
          this.router.navigate(
            [`/${PRODUCT_TYPE_ROUTE_MAP.get(productType)}/${action.payload.route}`],
            {
              queryParamsHandling: 'preserve',
            }
          );
        })
      ),
    { dispatch: false }
  );

  redirectToCheckInWizard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInActions.RedirectToCheckInWizard),
      map(() => CheckInActions.RedirectToProductRoute({ payload: { route: 'checkin' } }))
    )
  );

  redirectToSigningRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInActions.RedirectToSigningRoom),
      map(() => CheckInActions.RedirectToProductRoute({ payload: { route: 'signing-room' } }))
    )
  );

  redirectToWaitingRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInActions.RedirectToWaitingRoom),
      concatLatestFrom(() => [this.store.pipe(select(PackagesSelectors.isRONOrKRON))]),
      map(([_, isRONOrKRON]) => {
        const nextPage = isRONOrKRON ? 'waiting-room' : 'lobby';
        return CheckInActions.RedirectToProductRoute({ payload: { route: nextPage } });
      })
    )
  );

  redirectToWelcomePage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInActions.RedirectToWelcomePage),
      map(() => CheckInActions.RedirectToProductRoute({ payload: { route: 'welcome' } }))
    )
  );

  redirectToAudioVideoCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInActions.RedirectToAudioVideoCheck),
      map(() => CheckInActions.RedirectToProductRoute({ payload: { route: 'av-check' } }))
    )
  );

  saveWaitingRoomViewedForSessionParticipants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInActions.WaitingRoomViewed),
      concatLatestFrom(() => [
        this.store.select(PackagesSelectors.getActivePackageGuid),
        this.store.select(DeviceGroupSelectors.getDeviceCode),
        this.store.select(PackageUsersSelectors.getActivePackageUserGuid),
      ]),
      switchMap(([_, packageGuid, sessionId, activePackageUserGuid]) => {
        return this.waitingRoomService.viewed(packageGuid, sessionId, activePackageUserGuid).pipe(
          map(() => CheckInActions.WaitingRoomViewedSuccess()),
          catchError((error: Error) => of(CheckInActions.WaitingRoomViewedFailure({ error })))
        );
      })
    )
  );

  fetchWaitingRoomViewedByParticipants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CheckInActions.FetchWaitingRoomViewedByParticipants,
        CheckInActions.WaitingRoomViewedSuccess,
        CheckInActions.NonSigningAgentWelcomePageOpened
      ),
      concatLatestFrom(() => [
        this.store.select(PackagesSelectors.getActivePackageGuid),
        this.store.select(PackageUsersSelectors.getPackageUsers),
        this.store.select(CheckInSelectors.getUsersViewedWaitingRoom),
      ]),
      switchMap(([_, packageGuid]) => {
        return this.waitingRoomService.viewedByParticipants(packageGuid).pipe(
          map((packageUserGuids) =>
            CheckInActions.SetWaitingRoomViewedByParticipants({ payload: packageUserGuids })
          ),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to fetch waiting room viewed by participants',
                  exceptionType: ExceptionType.None,
                },
              })
            )
          )
        );
      })
    )
  );

  syncUserCheckInCompleteWithPackageUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInActions.SyncUserCheckInCompleteWithPackageUsers),
      concatLatestFrom(() => [
        this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
        this.store.pipe(select(CheckInSelectors.getUsersCompletedCheckIn)),
      ]),
      switchMap(([_, packageGuid, stateUsersCompletedCheckIn]) =>
        this.checkInStatusService.getPackageCheckInStatus(packageGuid).pipe(
          switchMap((users) => {
            const missingCheckinCompletedUsers = users
              .filter((u) => u.checkInStatus?.isCompleted)
              .map((u) => u.packageUserGuid)
              .filter((guid) => !stateUsersCompletedCheckIn.includes(guid));
            const actions: TypedAction<any>[] = [];
            missingCheckinCompletedUsers.forEach((packageUserGuid) => {
              actions.push(CheckInActions.SetUserCheckInComplete({ payload: { packageUserGuid } }));
            });

            return actions;
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to fetch user check-in statuses',
                  exceptionType: ExceptionType.None,
                },
              })
            )
          )
        )
      )
    )
  );

  checkAndUpdateIfSACompletedCheckIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInActions.CheckAndUpdateIfSACompletedCheckIn),
      concatLatestFrom(() => [this.store.pipe(select(PackageUsersSelectors.getSigningAgent))]),
      filter(([_, signingAgent]) => !!signingAgent),
      switchMap(([_, signingAgent]) =>
        this.packageUsersService.getPackageUserCheckInStatus(signingAgent?.packageUserGuid).pipe(
          filter((result) => result?.checkInStatus?.isCompleted),
          switchMap((result) => [
            PackageUsersActions.SetPackageUserCheckInStatus({
              payload: {
                packageUserGuid: result.packageUserGuid,
                checkInStatus: result.checkInStatus,
              },
            }),
          ])
        )
      )
    )
  );

  navigateAwayFromWelcomePage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CheckInActions.NavigateAwayFromWelcomePage),
        concatLatestFrom(() => [
          this.store.pipe(select(EndorsementsSelectors.areAllSystemEndorsementsApplied)),
          this.store.pipe(select(PackagesSelectors.getProductType)),
        ]),
        tap(([_, areAllSystemEndorsementsApplied, productType]) => {
          let page = 'lobby';

          if (areAllSystemEndorsementsApplied) {
            page = 'signing-room';
          }
          this.router.navigate([`/${PRODUCT_TYPE_ROUTE_MAP.get(productType)}/${page}`], {
            queryParamsHandling: 'preserve',
            replaceUrl: true,
          });
        })
      ),
    { dispatch: false }
  );

  showReloadRetryErrorNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInActions.WaitingRoomViewedFailure),
      map((action) =>
        NotificationsActions.AddNotification({
          payload: {
            id: uuid(),
            exception: action.error,
            notificationType: NotificationType.Error,
            exceptionType: ExceptionType.ReloadRetry,
            text: 'Something went wrong please try refreshing',
          },
        })
      )
    )
  );

  loadDeviceUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CheckInActions.NonSigningAgentWelcomePageOpened),
      concatLatestFrom(() => [
        this.store.select(PackagesSelectors.getActivePackageGuid),
        this.store.select(DeviceGroupSelectors.getDeviceCode),
      ]),
      switchMap(([_, activePackageGuid, deviceCode]) => {
        return this.deviceGroupService
          .getDeviceUsers(activePackageGuid, deviceCode)
          .pipe(
            map((deviceUsers) =>
              DeviceGroupActions.GetCurrentDeviceUsersSuccessful({ payload: { deviceUsers } })
            )
          );
      })
    );
  });

  continueToWaitingRoom$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CheckInActions.GoToWaitingRoom),
      concatLatestFrom(() => [
        this.store.select(PackageUsersSelectors.getPackageUsers),
        this.store.select(DeviceGroupSelectors.getUsersOnDevice),
      ]),
      map(([_, users, usersOnDevice]) => {
        const defaultUser = users.find(
          ({ packageUserGuid }) => packageUserGuid === usersOnDevice[0].packageUserGuid
        );
        return CheckInActions.PromptUserForAuthentication({
          payload: {
            participant: defaultUser,
          },
        });
      })
    );
  });

  continueToSigningRoom$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CheckInActions.GoToSigningRoom),
      concatLatestFrom(() => [
        this.store.select(PackageUsersSelectors.getPackageUsers),
        this.store.select(MsalAuthSelectors.getIsUserVerified),
        this.store.select(DeviceGroupSelectors.getUsersOnDevice),
      ]),
      map(([_, users, isUserVerified, usersOnDevice]) => {
        const defaultUser = users.find(
          ({ packageUserGuid }) => packageUserGuid === usersOnDevice[0].packageUserGuid
        );
        // User Verification is the verification granted from a kba or rejoin re authentication
        // When a user who previously rejoined, navigates back to the user selection page
        // they will have all the state that says they should go back to the signing except the verification to pass the guards
        // so in that case treat it as a rejoin
        if (isUserVerified) {
          return CheckInActions.PromptUserForAuthentication({
            payload: {
              participant: defaultUser,
            },
          });
        }

        return CheckInActions.BeginRejoin();
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly deviceGroupService: DeviceGroupService,
    private readonly router: Router,
    private readonly checkInStatusService: CheckInStatusService,
    private readonly waitingRoomService: WaitingRoomService,
    private readonly featureManagementService: FeatureManagementService,
    private readonly packageUsersService: PackageUsersService,
    private readonly store: Store,
    private readonly endorsementService: EndorsementsService
  ) {}
}
