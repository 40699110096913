import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, OnDestroy, Signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';

import { AudioVideoCheckService } from 'src/app/features/av-check/services/audio-video-check.service';
import { MicCheckService } from 'src/app/features/av-check/services/mic-check.service';
import { SpeakerCheckService } from 'src/app/features/av-check/services/speaker-check.service';

import { SharedModule } from 'src/app/features/shared';
import { AvCheckGuideComponent } from '../../shared/av-check-guide/av-check-guide.component';

@Component({
  selector: 'app-audio-select',
  standalone: true,
  imports: [
    AvCheckGuideComponent,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatSelectModule,
    MatProgressBarModule,
    SharedModule,
    CommonModule,
    FormsModule,
  ],
  templateUrl: './audio-select.component.html',
  styleUrl: './audio-select.component.scss',
})
export class AudioSelectComponent implements OnDestroy {
  audioVideoCheckService = inject(AudioVideoCheckService);
  micCheckService = inject(MicCheckService);
  speakerCheckService = inject(SpeakerCheckService);

  showMicrophoneGuideDetails = false;
  showSpeakerGuideDetails = false;

  constructor() {
    effect(() => {
      if (
        !this.audioVideoCheckService.hasUserMediaFailure() &&
        !!this.micCheckService.selectAudioInputDevices().length
      ) {
        this.micCheckService.startTracking(this.micCheckService.currentMicDevice());
      }
    });
  }

  shouldDisableSpeakerTestButton: Signal<boolean> = computed(
    () =>
      this.speakerCheckService.forceDisableTestSpeakerButton() ||
      this.shouldShowSpeakerGuide() ||
      !this.speakerCheckService.selectAudioOutputDevice() ||
      !this.speakerCheckService.selectAudioOutputDevices().length
  );

  shouldShowSpeakerGuide: Signal<boolean> = computed(
    () =>
      (this.audioVideoCheckService.hasUserMediaFailure() &&
        !this.speakerCheckService.hideUserMediaFailure()) ||
      (this.speakerCheckService.isSourceConnected() === false &&
        this.speakerCheckService.hasAudioTestPassed() === false)
  );

  speakerFailure: Signal<boolean> = computed(() =>
    this.speakerCheckService.hasSpeakerFeature()
      ? !this.speakerCheckService.selectAudioOutputDevice() ||
        !!this.audioVideoCheckService.hasUserMediaFailure()
      : false
  );

  ngOnDestroy(): void {
    globalThis.cancelAnimationFrame(this.speakerCheckService.audioDetectionAnimationFrame);
  }
}
