<section>
  <header>
    <h2 data-testid="av-check-heading-main"><ng-container *ngTemplateOutlet="h2Content" /></h2>
    <p>
      Ensure you can see yourself below and test your microphone and speakers. Verify that all
      checkmarks are green before proceeding.
    </p>
  </header>

  <div class="video-container" non-dialog>
    <ng-container *ngTemplateOutlet="videoContainer" />
  </div>

  <section>
    <header>
      <h2 data-testid="av-check-heading"><ng-container *ngTemplateOutlet="h2Content" /></h2>
      <p>
        Ensure you can see yourself
        <span dialog>below</span>
        <span non-dialog>on the left side</span>
        and test your microphone and speakers. Verify that all checkmarks are green before
        proceeding.
      </p>
    </header>

    <div class="responsive-container">
      <div class="video-container" dialog>
        <ng-container *ngTemplateOutlet="videoContainer" />
      </div>

      <div class="form-container">
        <app-video-selection></app-video-selection>
        <app-audio-select></app-audio-select>
        <app-button-container>
          @if (useDefaultActions) {
          <app-button
            (buttonClick)="continue()"
            [dataTestId]="'next-button-avcheck'"
            [isDisabled]="!areRequiredDevicesSelectedAndTested()"
            type="primary"
          >
            Camera and Audio Looks Good!
          </app-button>
          } @else {
          <ng-content></ng-content>
          }
        </app-button-container>
      </div>
    </div>
  </section>
</section>

<ng-template #h2Content>
  Camera and <span non-dialog>Audio</span><span dialog>audio settings</span>
</ng-template>

<ng-template #videoContainer>
  @if (!audioVideoCheckService.hasUserMediaFailure() && !!audioVideoCheckService.selectVideoDevice()
  && audioVideoCheckService.mediaStream()) {
  <video
    [autoplay]="true"
    [controls]="false"
    [muted]="true"
    [playsInline]="true"
    [srcObject]="audioVideoCheckService.mediaStream()"
    class="video-list"
  ></video>
  } @else {
  <img
    src="./assets/images/no-video-detected.svg"
    alt="No Video Detected"
    role="img"
    title="Video not detected"
  />
  }
</ng-template>
