import { Inject, Injectable } from '@angular/core';
import { from, fromEvent, Observable } from 'rxjs';
import { windowToken } from 'src/app/window.token';

@Injectable({
  providedIn: 'root'
})
export class NavigatorMediaDeviceWrapperService {

  constructor(@Inject(windowToken) private readonly windowRef: Window) { }

  getUserMedia(constraints?: MediaStreamConstraints): Observable<MediaStream> {
    return from(this.windowRef.navigator.mediaDevices.getUserMedia(constraints));
  }

  enumerateDevices(): Observable<MediaDeviceInfo[]> {
    return from(this.windowRef.navigator.mediaDevices.enumerateDevices());
  }

  onDeviceChange(): Observable<Event> {
    return fromEvent(this.windowRef.navigator.mediaDevices, 'devicechange');
  }

  browserSupportsDeviceChange(): boolean {
    return typeof(this.windowRef.navigator.mediaDevices.ondevicechange) != "undefined";
  }

  getOnMediaStreamTrackEnded<T>(track: MediaStreamTrack): Observable<T> {
    return fromEvent<T>(track, "ended");
  }

}
