import { HttpClient } from '@angular/common/http';
import { computed, inject, Injectable, Signal } from '@angular/core';

import { Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AudioVideoConnectionMonitorSelectors } from 'src/app/features/audio-video-connection-monitor/store';
import { AuditLog } from 'src/app/features/audit-logs';
import { AudioVideoCheckActions } from 'src/app/features/av-check/store';
import { AudioVideoCheckSelectors } from 'src/app/features/av-check/store/selectors';

@Injectable()
export class AudioVideoCheckService {
  private readonly deviceDetectorService = inject(DeviceDetectorService);
  private readonly http = inject(HttpClient);
  private readonly store = inject(Store);

  isChrome = this.deviceDetectorService.browser.toLowerCase().includes('chrome');
  isEdge = this.deviceDetectorService.browser.toLowerCase().includes('ms-edge-chromium');
  isSafari = this.deviceDetectorService.browser.toLowerCase().includes('safari');

  isBrowserDetected = this.isChrome || this.isEdge || this.isSafari;

  hasVideoSelectionError = computed(
    () =>
      !this.hasUserMediaFailure() && !!this.videoInputDevices().length && this.selectVideoDevice()
  );

  hasUserMediaFailure: Signal<string> = this.store.selectSignal(
    AudioVideoCheckSelectors.selectGetUserMediaFailure
  );

  mediaStream: Signal<MediaStream> = this.store.selectSignal(
    AudioVideoCheckSelectors.selectMediaStream
  );

  selectVideoDevice: Signal<MediaDeviceInfo> = this.store.selectSignal(
    AudioVideoConnectionMonitorSelectors.getSelectedVideoDevice
  );

  videoInputDevices: Signal<MediaDeviceInfo[]> = this.store.selectSignal(
    AudioVideoCheckSelectors.selectVideoInputDevices
  );

  clearVideo() {
    this.store.dispatch(AudioVideoCheckActions.ClearVideo());
  }

  selectVideo(video: MediaDeviceInfo) {
    this.store.dispatch(AudioVideoCheckActions.SelectVideo({ video }));
  }

  postStartedAudioVideoCheck(deviceCode: string, packageGuid: string) {
    return this.http.post(`audioVideoCheck/started`, {
      DeviceCode: deviceCode,
      PackageGuid: packageGuid,
    });
  }

  postSessionCapable(auditLog: AuditLog) {
    return this.http.post(`audioVideoCheck/capability`, auditLog);
  }
}
