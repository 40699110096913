import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AudioVideoConnectionMonitorSelectors } from 'src/app/features/audio-video-connection-monitor/store';
import { ClientSelectors } from 'src/app/features/client';
import { ClientTheme } from 'src/app/features/client/models';
import { ModalsActions, ModalSize } from 'src/app/features/modals';
import { MsalAuthSelectors } from 'src/app/features/msal-auth';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { PackagesSelectors, ProductType } from 'src/app/features/packages';
import { DEFAULT_APPLICATION_PHONE_NUMBER } from 'src/app/features/shared/constants';
import { SigningRoomSelectors } from 'src/app/features/signing-room/store/selectors';
import { RootStoreState } from 'src/app/store';
import { environment } from 'src/environments/environment';
import {
  AudioVideoModalComponent,
  IdVerifyComponent,
  SigningDetailsComponent,
} from '../components';


@Component({
  selector: 'app-nav-bar-signing-session',
  templateUrl: './nav-bar-signing-session.component.html',
  styleUrls: ['../nav-bar/nav-bar.component.scss'],
})
export class NavBarSigningSessionComponent implements OnInit {
  canViewOrderDetailsButton$: Observable<boolean>;
  canViewDeviceSettingsButton$: Observable<boolean>;
  canViewFaqButton$: Observable<boolean>;
  canViewIdVerifyButton$: Observable<boolean>;
  clientTheme$: Observable<ClientTheme>;
  onDestroyNotifier = new Subject();

  faqUrl = environment.clearsignFaqUrl;
  clearSignSupportNumber = DEFAULT_APPLICATION_PHONE_NUMBER;

  constructor( private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.canViewFaqButton$ = this.store.select(PackagesSelectors.isRON);

    this.canViewOrderDetailsButton$ = combineLatest([
      this.store.pipe(select(PackageUsersSelectors.getIsActivePackageUserSigningAgent)),
      this.store.pipe(select(MsalAuthSelectors.getIsUserVerified)),
    ]).pipe(map(([isSigningAgent, msalIsVerified]) => isSigningAgent || msalIsVerified));

    this.canViewDeviceSettingsButton$ = this.store.pipe(
      select(AudioVideoConnectionMonitorSelectors.getCanDeviceSettingsModalBeDisplayed)
    );

    this.clientTheme$ = this.store.pipe(select(ClientSelectors.getClientTheme));

    this.canViewIdVerifyButton$ = combineLatest([
      this.store.pipe(select(PackagesSelectors.getProductType)),
      this.store.pipe(select(SigningRoomSelectors.sessionJoined)),
      this.store.pipe(select(PackageUsersSelectors.getIsActivePackageUserSigningAgent)),
    ]).pipe(
      map(([product, inSession, isUserSigningAgent]) => {
        if (product !== ProductType.RemoteSigning) {
          return false;
        } else {
          return (inSession && isUserSigningAgent);
        }
      })
    );

    this.clientTheme$ = this.store.pipe(select(ClientSelectors.getClientTheme));
  }

  openDetailsModal() {
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: SigningDetailsComponent,
        },
      })
    );
  }

  openChangeAudioVideoModal() {
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: AudioVideoModalComponent,
          modalSize: ModalSize.large,
        },
      })
    );
  }

  openCancelSigningModal() {
    this.store.dispatch(ModalsActions.OpenCancelSigningModal());
  }

  openIdVerifyModal() {
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: IdVerifyComponent,
          modalSize: ModalSize.large,
        },
      })
    );
  }

}
