import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { RootStoreState } from 'src/app/store';

import { DeviceGroupSelectors } from '../../../device-group';
import { ModalsActions } from '../../../modals';
import { NotificationsActions, NotificationType } from '../../../notifications';
import { PackagesSelectors } from '../../../packages';
import { AudioVideoCheckService } from '../../services';
import { AudioVideoCheckActions } from '../actions';

import { AudioVideoConnectionMonitorSelectors } from 'src/app/features/audio-video-connection-monitor/store';
import { NavigatorMediaDeviceWrapperService } from 'src/app/features/audio-video-connection-monitor/services';

export const getDeviceByType = (device: MediaDeviceInfo, type: string, val: string) =>
  device[type] === val;

@Injectable()
export class AudioVideoCheckEffects {
  audioVideoCheckStarted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AudioVideoCheckActions.AudioVideoCheckStarted),
      withLatestFrom(
        this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
        this.store.pipe(select(PackagesSelectors.getActivePackageGuid))
      ),
      switchMap(([action, deviceCode, packageGuid]) => {
        return this.audioVideoCheckService.postStartedAudioVideoCheck(deviceCode, packageGuid).pipe(
          switchMap(() => [ModalsActions.HideLoadingSpinner()]),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to enter AV Check page',
                },
              }),
              ModalsActions.HideLoadingSpinner()
            )
          )
        );
      })
    )
  );

  saveSessionCapable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AudioVideoCheckActions.SaveAuditSessionCapable),
      withLatestFrom(
        this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
        this.store.pipe(select(DeviceGroupSelectors.getDeviceCode))
      ),
      switchMap(([action, packageGuid, deviceCode]) => {
        return this.audioVideoCheckService
          .postSessionCapable({
            PackageGuid: packageGuid,
            DeviceCode: deviceCode,
          })
          .pipe(
            map(() => AudioVideoCheckActions.SaveSessionCapableSuccessful()),
            catchError(() => of(AudioVideoCheckActions.SaveSessionCapableFailed()))
          );
      })
    )
  );

  setUserMediaStream$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AudioVideoCheckActions.SelectVideo),
      switchMap(({ video }: { video: MediaDeviceInfo }) => {
        const mediaConstraints = {
          video: { deviceId: video?.deviceId },
        };

        return this.navigatorMediaDeviceWrapperService.getUserMedia(mediaConstraints).pipe(
          map((mediaStream: MediaStream) => AudioVideoCheckActions.SetMediaStream({ mediaStream })),
          catchError((error) => of(AudioVideoCheckActions.GetUserMediaFailure({ error })))
        );
      })
    )
  );

  getUserMedia$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AudioVideoCheckActions.AudioVideoCheckOpened, AudioVideoCheckActions.GetUserMedia),
      switchMap(() => {
        return this.navigatorMediaDeviceWrapperService
          .getUserMedia({ audio: true, video: true })
          .pipe(
            map(() => AudioVideoCheckActions.GetUserMediaSuccess()),
            catchError((error: Error) => of(AudioVideoCheckActions.GetUserMediaFailure({ error })))
          );
      })
    );
  });

  enumerateDevices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AudioVideoCheckActions.GetUserMediaSuccess),
      switchMap(() =>
        this.navigatorMediaDeviceWrapperService
          .enumerateDevices()
          .pipe(map((devices) => AudioVideoCheckActions.EnumerateDevicesSuccess({ devices })))
      )
    );
  });

  enumerateDevicesSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AudioVideoCheckActions.EnumerateDevicesSuccess),
      withLatestFrom(
        this.store.select(AudioVideoConnectionMonitorSelectors.getSelectedVideoDevice)
      ),
      switchMap(
        ([{ devices }, selectedVideoDevice]: [
          { devices: MediaDeviceInfo[] },
          selectedVideoDevice: MediaDeviceInfo
        ]) => {
          const videoInputDevices: MediaDeviceInfo[] = devices.filter((device) =>
            getDeviceByType(device, 'kind', 'videoinput')
          );

          const selectedVideoInputDevice: MediaDeviceInfo[] = videoInputDevices.filter((device) =>
            getDeviceByType(device, 'deviceId', selectedVideoDevice?.deviceId)
          );

          const [isSelectedDeviceAvailable]: MediaDeviceInfo[] = videoInputDevices.filter(
            (device) => device.deviceId === selectedVideoDevice?.deviceId
          );

          const [video] = isSelectedDeviceAvailable ? selectedVideoInputDevice : videoInputDevices;

          return of(AudioVideoCheckActions.SelectVideo({ video }));
        }
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly audioVideoCheckService: AudioVideoCheckService,
    private readonly navigatorMediaDeviceWrapperService: NavigatorMediaDeviceWrapperService,
    private readonly store: Store<RootStoreState.State>
  ) {}
}
