import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';

import { AudioVideoConnectionMonitorModule } from 'src/app/features/audio-video-connection-monitor';
import { DuplicateSessionDetectorModule } from 'src/app/features/duplicate-session-detector';
import { FrequentlyAskedQuestionsButtonComponent } from 'src/app/features/layout/nav-bar/components/frequently-asked-questions/frequently-asked-questions-button.component';
import { SupportPhoneNumberButtonComponent } from 'src/app/features/layout/nav-bar/components/support-phone-number/support-phone-number-button.component';
import { ModalsModule } from 'src/app/features/modals/modals.module';
import { PackageUsersModule } from 'src/app/features/package-users';
import { PackagesModule } from 'src/app/features/packages';
import { SharedModule } from 'src/app/features/shared';

import {
  AudioVideoModalComponent,
  CancelSigningModalComponent,
  ExitSigningConfirmationComponent,
  FeedbackLayoutComponent,
  FooterComponent,
  HeaderSlimComponent,
  HelpModalComponent,
  IdVerifyComponent,
  InternetCheckComponent,
  OptOutModalComponent,
  PreSessionLayoutComponent,
  PreSignLayoutComponent,
  SideBarComponent,
  SigningDetailsComponent,
  SigningSessionLayoutComponent,
} from './';

import { AudioVideoCheckV2Component } from '../av-check/containers/audio-video-component-check-v2/audio-video-check-v2.component';
import { AudioVideoModalSwitchComponent } from './components/audio-video-modal-switch/audio-video-modal-switch.component';
import { AudioVideoModalV2Component } from './components/audio-video-modal-v2/audio-video-modal-v2.component';

import { ParticipantListFluidMaxHeightDirective } from './components/participant-list/participant-list-fluid-max-height/participant-list-fluid-max-height.directive';
import { ParticipantListTimerComponent } from './components/participant-list/participant-list-timer.component/participant-list-timer.component';
import { ParticipantListComponent } from './components/participant-list/participant-list.component';
import { SigningsMonthlyCardComponent } from './components/signings-monthly-card/signings-monthly-card.component';
import { NavBarPreSessionComponent } from './nav-bar-pre-session/nav-bar-pre-session.component';
import { NavBarPreSignComponent } from './nav-bar-pre-sign/nav-bar-pre-sign.component';
import { NavBarSessionEndingComponent } from './nav-bar-session-ending/nav-bar-session-ending.component';
import { NavBarSigningSessionComponent } from './nav-bar-signing-session/nav-bar-signing-session.component';
import { MenuComponent } from './nav-bar/components/menu/menu.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { PostSessionLayoutComponent } from './post-session-layout/post-session-layout.component';

@NgModule({
  declarations: [
    CancelSigningModalComponent,
    ExitSigningConfirmationComponent,
    FeedbackLayoutComponent,
    FooterComponent,
    HeaderSlimComponent,
    HelpModalComponent,
    IdVerifyComponent,
    InternetCheckComponent,
    OptOutModalComponent,
    PreSessionLayoutComponent,
    PreSignLayoutComponent,
    SideBarComponent,
    SigningDetailsComponent,
    SigningSessionLayoutComponent,
    AudioVideoModalComponent,
    AudioVideoModalSwitchComponent,
    AudioVideoModalV2Component,
    NavBarPreSessionComponent,
    ParticipantListComponent,
    SigningsMonthlyCardComponent,
    ParticipantListFluidMaxHeightDirective,
    NavBarPreSignComponent,
    NavBarComponent,
    NavBarSigningSessionComponent,
    NavBarSessionEndingComponent,
    PostSessionLayoutComponent,
    FrequentlyAskedQuestionsButtonComponent,
    SupportPhoneNumberButtonComponent,
    MenuComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ModalsModule,
    RouterModule.forChild([]),
    PackagesModule,
    PackageUsersModule,
    ReactiveFormsModule,
    AudioVideoCheckV2Component,
    AudioVideoConnectionMonitorModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    DuplicateSessionDetectorModule,
    MatProgressBarModule,
    ParticipantListTimerComponent,
  ],
  exports: [
    PreSessionLayoutComponent,
    PreSignLayoutComponent,
    SigningSessionLayoutComponent,
    FeedbackLayoutComponent,
    PostSessionLayoutComponent,
    ParticipantListComponent,
  ],
})
export class LayoutModule {}
